.contact__page {
    padding-bottom: 150px;
}

.contact__page__column {
    flex: 0 0 59.66667%;
    max-width: 59.66667%;
}

.template-contact-data {
    li {
        &::before {
            display: none;
        }
    }
}

.contact__page__video {
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $block-radius;
        overflow: hidden;
    }
}

.select2-container--default .select2-results__option {
    padding: 10px 30px;
    font-size: 1.188rem;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: #f4f4f4;
    color: $body-font-color;
}

.select2-container .select2-dropdown.select2-dropdown--above {
    border: 1px solid rgba(83, 83, 83, 0.4);
    border-bottom: 0;
    margin-bottom: -1px;
    box-shadow: 0 -23px 26px rgba(0, 0, 0, 0.16);
    z-index: 5;
}

.select2-container .select2-dropdown.select2-dropdown--below {
    border: 1px solid rgba(83, 83, 83, 0.4);
    border-top: 0;
    margin-top: -1px;
    box-shadow: 0 23px 26px rgba(0, 0, 0, 0.16);
    z-index: 5;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: $body-font-color;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 100%;

    &::before {
        position: absolute;
        top: 0;
        right: 20px;
        width: 20px;
        height: 100%;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' style='color: %23ff006c;' focusable='false' data-prefix='far' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z'%3E%3C/path%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: center center;
    }

    b {
        display: none;
    }
}

.contact__page__form {
    padding-left: 88px;

    .frm_forms {
        &.frm_style_formidable-style {
            &.with_frm_style {
                .form-field {
                    margin-bottom: 0;
                }
            }
        }

        &.with_frm_style {
            .frm_form_fields {
                > fieldset {
                    padding-bottom: 0;
                }
            }
        }

        label {
            display: none !important;
        }

        .frm_fields_container {
            grid-gap: 25px 2%;
        }

        .select2-container--default.select2-container--above {
            .select2-selection {
                &[aria-expanded="true"] {
                    border-top-right-radius: 0;
                    border-top-left-radius: 0;
                    border-top: 1px solid $white;
                }
            }
        }

        .select2-container--default.select2-container--below {
            .select2-selection {
                &[aria-expanded="true"] {
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                    border-bottom: 1px solid $white;
                }
            }
        }

        textarea {
            max-height: 170px;
        }

        textarea,
        input {
            &:focus,
            &:active {
                outline: 0;
                box-shadow: none !important;
                border-color: $body-font-color !important;
                border: 1px solid $body-font-color;
            }
        }

        .select2-container--default .select2-selection--single,
        select,
        textarea,
        input {
            border: 1px solid rgba(83, 83, 83, 0.4);
            font-size: 1.188rem;
            //padding: 15px 20px;
            padding: 28px 35px;
            height: inherit;
            box-shadow: none;
            color: $body-font-color;
            width: 100%;
            font-family: $graphik-regular;
            font-weight: 400;
            border-radius: $input-radius;

            .select2-selection__placeholder,
            &::placeholder {
                color: $body-font-color;
            }
        }

        &.frm_style_formidable-style.with_frm_style .frm_loading_form .frm_button_submit,
        &.frm_style_formidable-style.with_frm_style .frm_loading_form .frm_button_submit:hover {
            background: $white;
            color: $primarycolor !important;

            &::before {
                border: 0;
            }
        }

        .frm_submit {
            button {
                @extend .btn;
                @extend .btn--primary;

                padding-left: 67px;
                padding-right: 37px;
                padding-bottom: 10px;
                text-transform: lowercase;
                margin-bottom: 0;

                &:hover,
                &:active,
                &:focus {
                    background: $primarycolor;
                    color: $white;

                    &::before {
                        background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' style='color: %23ffffff;' focusable='false' data-prefix='far' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M501.6 4.186c-7.594-5.156-17.41-5.594-25.44-1.063L12.12 267.1C4.184 271.7-.5037 280.3 .0431 289.4c.5469 9.125 6.234 17.16 14.66 20.69l153.3 64.38v113.5c0 8.781 4.797 16.84 12.5 21.06C184.1 511 188 512 191.1 512c4.516 0 9.038-1.281 12.99-3.812l111.2-71.46l98.56 41.4c2.984 1.25 6.141 1.875 9.297 1.875c4.078 0 8.141-1.031 11.78-3.094c6.453-3.625 10.88-10.06 11.95-17.38l64-432C513.1 18.44 509.1 9.373 501.6 4.186zM369.3 119.2l-187.1 208.9L78.23 284.7L369.3 119.2zM215.1 444v-49.36l46.45 19.51L215.1 444zM404.8 421.9l-176.6-74.19l224.6-249.5L404.8 421.9z'%3E%3C/path%3E%3C/svg%3E%0A");
                    }
                }

                &::before {
                    position: absolute;
                    top: 0;
                    left: 30px;
                    width: 20px;
                    height: 100%;
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' style='color: %23ff006c;' focusable='false' data-prefix='far' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M501.6 4.186c-7.594-5.156-17.41-5.594-25.44-1.063L12.12 267.1C4.184 271.7-.5037 280.3 .0431 289.4c.5469 9.125 6.234 17.16 14.66 20.69l153.3 64.38v113.5c0 8.781 4.797 16.84 12.5 21.06C184.1 511 188 512 191.1 512c4.516 0 9.038-1.281 12.99-3.812l111.2-71.46l98.56 41.4c2.984 1.25 6.141 1.875 9.297 1.875c4.078 0 8.141-1.031 11.78-3.094c6.453-3.625 10.88-10.06 11.95-17.38l64-432C513.1 18.44 509.1 9.373 501.6 4.186zM369.3 119.2l-187.1 208.9L78.23 284.7L369.3 119.2zM215.1 444v-49.36l46.45 19.51L215.1 444zM404.8 421.9l-176.6-74.19l224.6-249.5L404.8 421.9z'%3E%3C/path%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: 20px;
                    background-position: center 20px;
                }
            }
        }
    }
}

.marker__inner {
    display: block;
    padding: 8px 10px 8px 10px;
}

.marker__title {
    display: block;
    margin: 0 0 8px 0;
    font-size: 1.125rem;
    font-weight: 700;
    text-align: center;
    color: $primarycolor;
}

.marker__item {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    font-size: 0.938rem;
    text-align: center;

    &.btn {
        margin: 15px 0 0 0;
    }
}

.contact__form__inner {
    padding: 35px 40px;
    border-radius: $border-radius;
    background-color: #f3f3f3;

    .gfield {
        input,
        select,
        textarea {
            border: 1px solid transparent;
            background-color: #fff;
        }

        textarea {
            max-height: 180px;
        }
    }
}

.contact__page__locationtitle {
    margin-bottom: 60px;
}

.contact__page__title {
    margin-bottom: 120px;
}

.contact__page__formsection {
    margin-bottom: 120px;
}

.contact__form__title {
    display: block;
    margin-bottom: 15px;
    font-size: 1.125rem;
    font-weight: 700;
}

.contact__form__text {
    display: block;
    margin-bottom: 25px;
}

.contact__company {
    margin-bottom: 20px;
    font-size: 1.25rem;
    color: $primarycolor;
    font-family: $headings-font-family;
}

.single__content {
    .contact__company {
        margin-top: 20px;
    }

    .contact__page__emails {
        margin-bottom: 80px;

        .contact__page__email__item {
            display: flex;
            flex-direction: column;

            strong {
                display: inherit;
                font-size: 1.25rem;
                font-weight: 700;
                margin-bottom: 3px;
                font-family: $headings-font-family;
            }

            a {
                font-size: 1.188rem;
                color: $body-font-color;
                position: relative;
                align-self: flex-start;
                display: inline-block;

                &::before {
                    content: '';
                    bottom: 5px;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background: $primarycolor;
                    position: absolute;
                    transition: all 300ms ease-in-out;
                }

                &:hover,
                &:active,
                &:focus {
                    text-decoration: none;
                    color: $primarycolor;

                    &::before {
                        width: 100%;
                    }
                }
            }
        }
    }

    .contact__link {
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }
    }

    .contact__links__wrap {
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
    }

    .contact__terms__link {
        color: $primarycolor;
        position: relative;
        display: inline-block;
        align-self: flex-start;
        margin-bottom: 5px;

        &::before {
            content: '';
            bottom: 0;
            left: 0;
            width: 0;
            height: 2px;
            background: $primarycolor;
            position: absolute;
            transition: all 300ms ease-in-out;
        }

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;

            &::before {
                width: 100%;
            }
        }
    }
}

.contact__address {
    margin-bottom: 0;
    line-height: 33px;

    span {
        display: block;
    }
}

.contact__accessibility {
    margin: 0 0 45px;
    padding: 0;
    list-style: none;
    line-height: 33px;
}

.contact__icon {
    margin-right: 10px;

    svg {
        width: 16px;
        height: 16px;
        color: $primarycolor;
        fill: $primarycolor;
    }
}

.contact__line {
    display: flex;
    line-height: 31px;
}

.contact__text {
    transition: all 0.3s;
    color: $body-font-color;
}

.contact__details {
    margin-bottom: 30px;
}

.contact__location {
    margin-bottom: 40px;
    font-size: 1.188rem;

    &:last-child {
        margin-bottom: 0;
    }

    li {
        padding-left: 0;
    }

    .social__icons {
        display: flex;
        gap: 25px;
    }

    .social__icon__item {
        margin-right: 0;
        display: block;
        transition: all 300ms ease-in-out;

        &:focus,
        &:hover {
            opacity: 0.75;
        }

        &.social__icon__item--facebook {
            svg {
                width: 16px;
                height: inherit;
            }
        }

        &.social__icon__item--instagram,
        &.social__icon__item--linkedin {
            svg {
                width: 27px;
                height: inherit;
            }
        }

        &.social__icon__item--youtube {
            svg {
                width: 30px;
                height: inherit;
            }
        }
    }
}

.contact__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:focus,
    &:hover {
        .contact__text {
            color: $primarycolor;
        }
    }
}

.contact__line__col {
    width: 75px;
    flex: 0 0 75px;
}

.contact__image {
    margin-top: 20px;
    border-radius: $block-radius;
    overflow: hidden;
    width: 100%;
    max-width: 609px;
    max-height: 482px;
    object-fit: cover;
}

.contact__image__mobile,
.contact__page__video__mobile {
    display: none;
}

@media screen and (max-width: 1699px) {
    .contact__page__column {
        flex: 0 0 65%;
        max-width: 65%;
    }
}

@media screen and (max-width: 1549px) {
    .contact__page__column {
        flex: 0 0 72%;
        max-width: 72%;
    }
}

@media screen and (max-width: 1499px) {
    .contact__page__column {
        flex: 0 0 75%;
        max-width: 75%;
    }
}

@media screen and (max-width: 1349px) {
    .contact__page__column {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
}

@media screen and (max-width: 1199px) {
    .contact__page {
        padding-bottom: 110px;
    }

    .select2-container--default .select2-results__option {
        font-size: 1.063rem;
        padding: 10px 15px;
    }

    .contact__page__video {
        padding-right: 22px;
    }

    .single__content {
        .contact__terms__link {
            font-size: 1rem;
        }

        .contact__page__emails {
            .contact__page__email__item {
                strong {
                    font-size: 1.063rem;
                }

                a {
                    font-size: 1rem;
                }
            }
        }
    }

    .contact__company {
        font-size: 1.125rem;
    }

    .contact__line,
    .contact__details,
    .contact__accessibility,
    .contact__address {
        font-size: 1rem;
        line-height: 29px;
    }

    .contact__page__form {
        padding-left: 22px;

        .frm_forms {
            .select2-container--default .select2-selection--single,
            select,
            textarea,
            input {
                font-size: 1.063rem;
                padding: 15px;
            }

            .frm_submit {
                button {
                    font-size: 1.375rem;

                    &::before {
                        background-size: 16px;
                        background-position: center 16px;
                    }
                }
            }
        }
    }

    .select2-container .select2-selection--single .select2-selection__rendered {
        padding: 0;
    }
}

@media screen and (max-width: 991px) {
    .contact__page__column {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .contact__image {
        display: none;
    }

    .contact__image__mobile {
        display: block;
        width: 100%;
        margin-bottom: 60px;
    }

    .contact__page__video__mobile {
        display: block;
        margin-bottom: 65px;

        video {
            width: 100%;
            max-height: 650px;
            object-fit: cover;
            object-position: center;
        }
    }

    .single__content {
        &.contact__page {
            padding-bottom: 0;
        }

        .contact__links__wrap {
            margin-bottom: 15px;
        }

        .contact__company {
            margin-top: 0;
        }

        .contact__page__emails {
            margin-bottom: 20px;

            .contact__page__email__item {
                margin-bottom: 30px;
            }
        }
    }

    .contact__location {
        .social__icon__item {
            &.social__icon__item--facebook {
                svg {
                    width: 13px;
                }
            }

            &.social__icon__item--instagram,
            &.social__icon__item--linkedin {
                svg {
                    width: 22px;
                }
            }
        }
    }

    .contact__accessibility {
        margin-bottom: 10px;
    }

    .contact__details {
        margin-bottom: 15px;
    }

    .social__icons {
        margin-bottom: 70px;
    }

    .contact__page__locationtitle {
        margin-bottom: 30px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 1.625rem;
            text-align: left !important;
        }
    }

    .contact__page__video {
        padding-right: 12px;
        display: none;
    }

    .contact__page__formsection {
        margin-bottom: 70px;
    }

    .contact__page__form {
        padding-left: 12px;
    }

    .contact__page__title {
        margin-bottom: 70px;
    }

    .contact__main {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 767px) {
    .contact__page__form {
        .frm_forms {
            .frm_fields_container {
                grid-gap: 15px 2%;
            }
        }
    }

    .contact__page {
        padding-bottom: 70px;

        .frm_forms {
            .select2-container--default .select2-selection--single,
            select,
            textarea,
            input {
                padding: 18px 25px;
                line-height: 23px;
            }

            .select2-container--default .select2-selection--single .select2-selection__rendered {
                line-height: 23px;
            }
        }
    }

    .contact__form__inner {
        padding: 27px 30px 33px 30px;
    }

    .contact__image {
        max-width: 100%;
        margin-top: 35px;
    }
}

@media screen and (max-width: 575px) {

}
