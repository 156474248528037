.page-template-landing {
    .fancybox-content {
        width: 100%;
        max-width: 600px;
    }

    .frm_forms {
        &.frm_style_formidable-style {
            &.with_frm_style {
                .form-field {
                    margin-bottom: 0;
                }
            }
        }

        &.with_frm_style {
            .frm_form_fields {
                > fieldset {
                    padding-bottom: 0;
                }
            }
        }

        label {
            display: none !important;
        }

        .frm_fields_container {
            grid-gap: 25px 2%;
        }

        .select2-container--default.select2-container--above {
            .select2-selection {
                &[aria-expanded="true"] {
                    border-top-right-radius: 0;
                    border-top-left-radius: 0;
                    border-top: 1px solid $white;
                }
            }
        }

        .select2-container--default.select2-container--below {
            .select2-selection {
                &[aria-expanded="true"] {
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                    border-bottom: 1px solid $white;
                }
            }
        }

        textarea {
            max-height: 170px;
        }

        textarea,
        input {
            &:focus,
            &:active {
                outline: 0;
                box-shadow: none !important;
                border-color: $body-font-color !important;
                border: 1px solid $body-font-color;
            }
        }

        .select2-container--default .select2-selection--single,
        select,
        textarea,
        input {
            border: 1px solid rgba(83, 83, 83, 0.4);
            font-size: 1.188rem;
            //padding: 15px 20px;
            padding: 28px 35px;
            height: inherit;
            box-shadow: none;
            color: $body-font-color;
            width: 100%;
            font-family: $graphik-regular;
            font-weight: 400;
            border-radius: $input-radius;

            .select2-selection__placeholder,
            &::placeholder {
                color: $body-font-color;
            }
        }

        &.frm_style_formidable-style.with_frm_style .frm_loading_form .frm_button_submit,
        &.frm_style_formidable-style.with_frm_style .frm_loading_form .frm_button_submit:hover {
            background: $white;
            color: $primarycolor !important;

            &::before {
                border: 0;
            }
        }

        .frm_submit {
            button {
                @extend .btn;
                @extend .btn--primary;

                padding-left: 67px;
                padding-right: 37px;
                padding-bottom: 10px;
                text-transform: lowercase;
                margin-bottom: 0;

                &:hover,
                &:active,
                &:focus {
                    background: $primarycolor;
                    color: $white;

                    &::before {
                        background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' style='color: %23ffffff;' focusable='false' data-prefix='far' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M501.6 4.186c-7.594-5.156-17.41-5.594-25.44-1.063L12.12 267.1C4.184 271.7-.5037 280.3 .0431 289.4c.5469 9.125 6.234 17.16 14.66 20.69l153.3 64.38v113.5c0 8.781 4.797 16.84 12.5 21.06C184.1 511 188 512 191.1 512c4.516 0 9.038-1.281 12.99-3.812l111.2-71.46l98.56 41.4c2.984 1.25 6.141 1.875 9.297 1.875c4.078 0 8.141-1.031 11.78-3.094c6.453-3.625 10.88-10.06 11.95-17.38l64-432C513.1 18.44 509.1 9.373 501.6 4.186zM369.3 119.2l-187.1 208.9L78.23 284.7L369.3 119.2zM215.1 444v-49.36l46.45 19.51L215.1 444zM404.8 421.9l-176.6-74.19l224.6-249.5L404.8 421.9z'%3E%3C/path%3E%3C/svg%3E%0A");
                    }
                }

                &::before {
                    position: absolute;
                    top: 0;
                    left: 30px;
                    width: 20px;
                    height: 100%;
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' style='color: %23ff006c;' focusable='false' data-prefix='far' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M501.6 4.186c-7.594-5.156-17.41-5.594-25.44-1.063L12.12 267.1C4.184 271.7-.5037 280.3 .0431 289.4c.5469 9.125 6.234 17.16 14.66 20.69l153.3 64.38v113.5c0 8.781 4.797 16.84 12.5 21.06C184.1 511 188 512 191.1 512c4.516 0 9.038-1.281 12.99-3.812l111.2-71.46l98.56 41.4c2.984 1.25 6.141 1.875 9.297 1.875c4.078 0 8.141-1.031 11.78-3.094c6.453-3.625 10.88-10.06 11.95-17.38l64-432C513.1 18.44 509.1 9.373 501.6 4.186zM369.3 119.2l-187.1 208.9L78.23 284.7L369.3 119.2zM215.1 444v-49.36l46.45 19.51L215.1 444zM404.8 421.9l-176.6-74.19l224.6-249.5L404.8 421.9z'%3E%3C/path%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: 20px;
                    background-position: center 20px;
                }
            }
        }
    }

    .single__content {
        text-align: center;
        padding-top: 92px;
    }

    .page-layer__gray {
        background: #f8f8f8;
        border-radius: 30px;
        padding: 54px;
        row-gap: 30px;
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    .landing__intro {
        position: relative;
        min-height: 850px;
        display: flex;
        align-items: center;
        padding-top: 80px;

        &__inner {
            z-index: 1;
            position: relative;
        }

        &__img {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 0;
        }

        [class^="content-"] {
            h2 {
                font-size: 3.75rem;
                margin-bottom: 37px;
            }

            p {
                font-size: 1.5rem;
                line-height: 39px;
            }
        }
    }

    .landing__intro__buttons {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;
        margin-top: 43px;
        position: relative;
        z-index: 1;

        a {
            margin-bottom: 15px;
        }

        .btn--tertiary svg path {
            fill: #fff;
        }
    }

    .packages {
        background: #f8f8f8;
        padding-top: 74px;
        padding-bottom: 95px;
        text-align: center;

        h2 {
            font-size: 5rem;
        }

        .packages__holder {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: 24px;
            margin-top: 62px;
            text-align: left;

            &__inner {
                padding: 40px;
                background: #fff;
                border-radius: 32px;

                &--title {
                    font-size: 1.625rem;
                }

                &--hourspermonth {
                    background: rgba(255, 0, 108, 0.1);
                    color: #ff006c;
                    font-size: 1.938rem;
                    font-family: 'Fredoka-Bold';
                    min-height: 63px;
                    padding: 0 30px;
                    border-radius: 32px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                }

                &--content {
                    margin-top: 24px;
                    font-size: 1.313rem;
                }

                &--listtitle {
                    margin-top: 35px;
                    font-size: 1.563rem;
                    font-family: 'Fredoka-Bold';
                }

                &--list {
                    margin-top: 26px;
                    margin-bottom: 50px;

                    li {
                        padding-left: 40px;

                        &:not(:last-child) {
                            margin-bottom: 35px;
                        }

                        &::before {
                            min-width: 24px;
                            max-height: 24px;
                            max-width: 24px;
                            min-height: 24px;
                            border: none;
                            background: url('../../images/check-solid-pink.svg') no-repeat top;
                            top: 2px;
                        }
                    }
                }

                &--undertitle {
                    color: #939393;
                    font-size: 1.125rem;
                    margin-bottom: 35px;
                }

                &--button {
                    .btn {
                        font-size: 1.375rem;
                    }
                }
            }
        }
    }

    @media (max-width: 1400px) {
        .packages .packages__holder {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    @media (max-width: 1199px) {
        .single__content {
            padding-top: 92px;
        }

        .packages {
            h2 {
                font-size: 2.5rem;
            }
        }

        .packages .packages__holder__inner--list li:not(:last-child) {
            margin-bottom: 10px;
        }

        .packages .packages__holder__inner--listtitle {
            margin-top: 20px;
        }

        .packages .packages__holder__inner--undertitle {
            margin-bottom: 20px;
        }

        .packages .packages__holder__inner--list {
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }

    @media (max-width: 991px) {
        .landing__intro {
            min-height: initial;
            padding-top: 110px;
            margin-bottom: 80px;

            [class^="content-"] {
                h2 {
                    font-size: 2.5rem;
                    margin-bottom: 24px;
                }

                p {
                    font-size: 1.313rem;
                    line-height: 33px;
                }
            }
        }
    }

    @media (max-width: 767px) {
        .page-layer--columns {
            align-items: center;
        }

        .landing__intro__buttons {
            gap: 10px;
            margin-top: 30px;
        }

        .team-member__content {
            margin-bottom: 0;
        }

        .packages {
            padding-top: 70px;
            padding-bottom: 70px;
        }

        .packages .packages__holder {
            margin-top: 40px;
        }

        .landing__intro {
            margin-bottom: 70px;
        }

        .packages .packages__holder__inner--list li::before {
            min-width: 20px;
            max-height: 20px;
            max-width: 20px;
            min-height: 20px;
            top: 6px;
        }

        .single__content {
            padding-top: 70px;
        }
    }
}
