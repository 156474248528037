/* Globals */
.page-layers {
    display: flex;
    flex-direction: column;
    row-gap: 65px;
    overflow-x: clip;
    overflow-y: visible;

    &:last-child {
        padding-bottom: 65px;
    }

    > * {
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}

/* Remove gap with min margins for certain layers */
.page-layer--collection,
.page-layer--cta {
    + .page-layer--collection,
    + .page-layer--cta {
        margin-top: -65px;
    }
}

/* Individual globals */
.page-layer {
    display: flex;
    align-items: flex-start;
    gap: 60px;

    &.page-layer--pinkblock {
        margin-top: 0;
        margin-bottom: 0;
    }

    iframe {
        max-width: 100%;
    }

    a:not(.btn):not(.team-member__button):not(.team-member__media):not(.team-member__title):not(.team-member__image) {
        color: $primarycolor;
        position: relative;
        align-self: flex-start;
        display: inline-block;

        &::before {
            content: '';
            bottom: 0;
            left: 0;
            width: 0;
            height: 2px;
            background: $primarycolor;
            position: absolute;
            transition: all 300ms ease-in-out;
        }

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            color: $primarycolor;

            &::before {
                width: 100%;
            }
        }
    }
}

.page-layer--bg-primary {
    background-color: $primarycolor;
}

.page-layer--bg-secondary {
    background-color: $secondarycolor;
}

.page-layer__partial {
    flex: 1 1 100%;
}

.page-layer__partial--full {
    flex: 0 0 100%;
}

.page-layer__partial--title {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 15px;
        margin-top: 15px;
    }
}

.page-layer__partial--button {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

.page-layer--intro-columns {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .btn {
        margin-top: 15px;
    }

    .intro-column__wrap {
        width: 100%;
    }

    .intro-column {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 30px;

        .intro-column__left {
            text-align: right;
        }
    }
}

/* Layer: Title & intro */
.page-layer--intro {
    column-gap: 160px;
    row-gap: 25px;

    .page-layer__partial {
        flex-basis: 50%;
    }
}

/* Layer: Banner */
.page-layer--banner {
    position: relative;
    overflow: hidden;

    .page-layer--leftbar {
        position: absolute;
        content: '';
        height: 100%;
        width: 120px;
        left: 0;
        top: 0;
        background: $white;
        transform: translateX(0);
        z-index: 5;
    }

    .page-layer--rightbar {
        position: absolute;
        content: '';
        height: 100%;
        width: 120px;
        right: 0;
        top: 0;
        background: $white;
        transform: translateX(0);
        z-index: 5;
    }

    .page-layer__partial--image {
        img {
            display: block;
            width: 100%;
            max-width: 100%;
        }
    }

    .page-layer--collection {
        overflow: hidden;

        .page-layer__items {
            flex: inherit;
            max-width: inherit;
            display: block;
            margin: 0 -38px 0 -38px;

            .slick-slide {
                margin-left: 8px;
                margin-right: 8px;
            }

            .block {
                display: block;
                flex: inherit;
                max-width: inherit;
                margin-bottom: 0;
                padding: 0;
            }
        }
    }
}

/* Layer: Text columns */
.page-layer--columns {
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 40px;

    &.page-layer--two-columns {
        .text__reveal {
            display: flex;
            column-gap: 40px;
        }
    }

    .page-layer__partial {
        flex-basis: calc(50% - 20px);
    }

    .page-layer__partial--full {
        flex-basis: 100%;
    }

    .page-layer__partial--title {
        flex-basis: 100%;
    }
}

/* Layer: Image(s) */
.page-layer--images {
    align-items: center;

    .reveal__wrap {
        width: 100%;
    }

    img {
        display: block;
        width: 100%;
    }

    &.page-layer--two-images {
        column-gap: 95px;
    }

    &.page-layer--three-images {
        column-gap: 24px;
    }

    .page-layer__inner {
        position: relative;
        overflow: hidden;
        display: block;
        width: 100%;
        border-radius: $block-radius;

        &:hover {
            .page-layer__overlay {
                opacity: 1;
            }

            .page-layer__overlay__overlay--inner {
                transform: translateY(0);
                opacity: 1;
                transition-delay: 0.15s;
                transition-duration: 0.3s;
            }
        }
    }

    .page-layer__overlay {
        position: absolute;
        inset: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 65px 55px;
        background: rgba(0, 0, 0, 0.61);
        opacity: 0;
        font-size: 1.313rem;
        color: #fff;
        transition: opacity 0.3s ease-in-out;
    }

    .page-layer__overlay__overlay--inner {
        position: relative;
        transform: translateY(50px);
        display: flex;
        flex-direction: column;
        opacity: 0;
        transition: all 0s ease-in-out;
        transition-delay: 0.3s;
    }

    .page-layer__title {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 16px;
        font-size: 1.875rem;
        color: #fff;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .page-layer__paragraph {
        display: -webkit-box;
        margin-bottom: 16px;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .page-layer__link {
        color: $primarycolor;

        &:not(.btn):not(.team-member__button)::before {
            width: 100%;
        }

        &:hover {
            &:not(.btn):not(.team-member__button)::before {
                width: 0;
            }
        }
    }
}

/* Layer: Team */
.page-layer--team {
    display: block;

    .page-layer__partial {
        flex: 0 0 calc(50% - 20px);
    }

    .page-layer__partial--title {
        margin-bottom: 45px;
        text-align: center;
    }

    .page-layer__partial--team {
        width: 100%;
        display: flex;
        justify-content: center;
        column-gap: 100px;

        .team-member {
            width: 100%;
            height: 100%;
            max-width: 365px;
        }
    }
}

/* Layer: CTA block (centered) */
.page-layer--cta {
    max-height: 100vh;
    padding: 245px 70px 190px;
    flex-direction: column;
    align-items: center;

    .page-layer__partial--title {
        @extend h1;

        max-width: 1000px;
        margin: 0;
        text-align: center;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            text-align: center !important;
            color: #fff;
        }
    }

    &.page-layer--bg-primary {
        .text--pink {
            color: $secondarycolor;
        }
    }
}

/* Layer: Collection */
.page-layer--collection {
    padding: 180px 0 125px 0;

    &.page-layer--services {
        padding: 40px 0 125px 0;
    }

    &.page-layer--news {
        padding: 0;
        margin: 0 0 90px 0;

        .page-layer__row {
            display: flex;
            padding-left: calc((100vw - 1694px) / 2);
        }

        .slick-slide {
            width: 424px;
            margin: 0 35px;
        }

        .block {
            display: block !important;
            height: 100%;
            margin-bottom: 0;
            padding: 4px 4px 0 4px;
        }

        .page-layer__content {
            flex: 0 0 32%;
            max-width: 32%;
            padding-right: 100px;

            a {
                font-size: 1.313rem;
                font-family: $fredoka-regular;
                color: $primarycolor;
                position: relative;
                align-self: flex-start;
                display: inline-block;

                span {
                    position: relative;
                    display: inline-block;

                    &::before {
                        content: '';
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        background: $primarycolor;
                        position: absolute;
                        transition: all 300ms ease-in-out;
                    }
                }

                svg {
                    color: $primarycolor;
                    fill: $primarycolor;
                    width: 18px;
                    height: 15px;
                    margin-left: 25px;
                    transition: all 300ms ease-in-out;
                }

                &:hover,
                &:active,
                &:focus {
                    text-decoration: none;
                    color: $secondarycolor;

                    span {
                        &::before {
                            background: $secondarycolor;
                        }
                    }

                    svg {
                        margin-left: 28px;
                    }
                }
            }
        }

        .page-layer__content__content {
            margin-top: 40px;
            margin-bottom: 30px;
            font-size: 1.5rem;

            h2 {
                margin-bottom: 35px;
            }
        }

        .page-layer__items {
            display: flex;
            flex: 0 0 68%;
            max-width: 68%;
        }
    }
}

.home {
    .page-layer--collection {
        &.page-layer--news {
            background-color: $primarycolor;
            padding: 140px 0 120px 0;
            z-index: 10000;

            .text--pink {
                color: #fff;
            }

            .block__title {
                color: #fff;
            }

            .block__tags a {
                color: #fff;

                &:focus-within,
                &:hover {
                    color: #000;
                }
            }

            .page-layer__content {
                color: #fff;

                a {
                    color: #fff;

                    span {
                        &::before {
                            background: #fff;
                        }
                    }

                    svg {
                        background-color: #fff;
                        width: 48px;
                        height: 48px;
                        margin-left: 25px;
                        padding: 15px;
                        border-radius: 50%;
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        text-decoration: none;
                        color: $secondarycolor;

                        span {
                            &::before {
                                background: $secondarycolor;
                            }
                        }

                        svg {
                            margin-left: 28px;
                        }
                    }
                }
            }
        }
    }
}

/* Layer: Projects */
.page-layer--projects {
    padding-top: 0;

    &:first-child {
        padding-top: 70px;
    }

    &:last-child {
        padding-bottom: 0;
    }

    .slick-list {
        padding-left: calc((100vw - 1420px) / 2);
    }

    .slick-slide {
        width: 686px;
        margin: 0 23px;
    }

    .block__reveal {
        //opacity: 1;
        //visibility: inherit;
        //transform: translate(0, 0);
    }

    .project__content {
        display: block !important;
        padding: 4px;
    }

    .project__content__slider-arrows {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 18px;
        margin-top: 45px;
    }

    .project__content__slider-arrow {
        transition: opacity 0.15s;
        cursor: pointer;

        &--prev {
            transform: scaleX(-1);
        }

        &.slick-disabled {
            cursor: default;
            opacity: 0.5;
        }

        svg {
            width: 45px;
            height: 45px;
        }
    }

    &.page-layer--collection {
        background-color: $white;
    }

    .page-layer__items {
        > .reveal__wrap {
            flex: 0 0 50%;
            max-width: 50%;
            height: 100%;
            margin-bottom: 0;
            padding: 0 23px;

            &:nth-of-type(even) {
                &::before {
                    display: none;
                }
            }
        }
    }
}

/* Layer: Reference */
.page-layer--reference {
    flex-direction: column;
    gap: 55px;
    padding: 125px;
    background-color: #000;
    border-radius: $block-radius;
    color: #fff;

    .page-layer__partial--title {
        @extend h2;

        width: 100%;
        margin: 0;
        text-align: center;
        color: #fff;
    }

    .page-layer__partial--name {
        width: 100%;
        font-size: 1.75rem;
        text-align: center;
        color: $primarycolor;
    }
}

/* Layer: Pink block */
.page-layer--pinkblock {
    flex-direction: column;
    gap: 55px;
    padding: 40px 50px 30px 50px;
    background-color: $primarycolor;
    border-radius: $block-radius;
    color: #fff;
    margin-top: 45px;
    margin-bottom: 45px;

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: #fff;
        margin: 0 0 15px 0;
    }

    ul {
        li::before {
            border-color: #fff;
        }

        &.check {
            li::before {
                background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' style='color: %23ffffff;' focusable='false' data-prefix='far' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'%3E%3C/path%3E%3C/svg%3E%0A");
            }
        }
    }
}

/* Layer: Collaborators */
.page-layer--collaborators {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 30px;

    .page-layer__partial--title {
        text-align: center;
    }

    .single__news__team__video {
        margin: 0;
    }
}

.wp-video {
    margin-bottom: 35px;
}

@media screen and (max-width: 1900px) {
    .page-layer--banner {
        .page-layer--leftbar,
        .page-layer--rightbar {
            width: 60px;
        }
    }

    .page-layer--collection.page-layer--news .page-layer__row {
        padding: 0 0 0 120px;
    }
}

@media screen and (max-width: 1800px) {
    .page-layer--collection {
        &.page-layer--news {
            .page-layer__content {
                flex: 0 0 40%;
                max-width: 40%;
                padding-right: 70px;
            }

            .page-layer__items {
                flex: 0 0 60%;
                max-width: 60%;
            }
        }
    }
}

@media screen and (max-width: 1539px) {
    .page-layer--projects {
        .slick-list {
            padding: 0 60px;
        }
    }
}

@media screen and (max-width: 1500px) {
    .page-layer--intro {
        column-gap: 40px;
    }

    .page-layer--images {
        .page-layer__overlay {
            padding: 45px 40px;
        }

        &.page-layer--three-images {
            .page-layer__paragraph {
                -webkit-line-clamp: 2;
            }
        }
    }
}

@media screen and (max-width: 1275px) {
    .page-layer--images {
        &.page-layer--two-images {
            .page-layer__paragraph {
                -webkit-line-clamp: 2;
            }
        }

        &.page-layer--three-images {
            .page-layer__paragraph {
                -webkit-line-clamp: 1;
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .page-layers {
        row-gap: 50px;

        &:last-child {
            padding-bottom: 50px;
        }
    }

    .page-layer__partial--title {
        h1 {
            font-size: 2.5rem;
        }

        h2 {
            font-size: 2.25rem;
        }

        h3,
        h4,
        h5, {
            font-size: 1.375rem;
        }
    }

    .wp-video {
        margin-bottom: 25px;
    }

    .page-layer--team .page-layer__partial--team {
        width: 66.666%;
        margin: 0 auto;
        column-gap: 40px;
    }

    /* Remove gap with min margins for certain layers */
    .page-layer--collection,
    .page-layer--cta {
        + .page-layer--collection,
        + .page-layer--cta {
            margin-top: -70px;
        }
    }

    .page-layer--images {
        .page-layer__overlay {
            font-size: 1rem;
        }

        .page-layer__title {
            font-size: 1.5rem;
        }

        &.page-layer--two-images {
            column-gap: 60px;
        }
    }

    .page-layer--cta {
        gap: 50px;
        padding: 140px 60px 100px;
    }

    .page-layer--collection {
        padding: 110px 0 85px 0;

        &.page-layer--services {
            padding: 40px 0 85px 0;
        }

        &.page-layer--projects {
            .reveal__wrap {
                height: 100%;
            }

            .project__content__slider-arrows {
                margin-top: 25px;
            }

            .project__content__slider-arrow {
                transition: opacity 0.15s;
                cursor: pointer;

                &--prev {
                    transform: scaleX(-1);
                }

                &.slick-disabled {
                    cursor: default;
                    opacity: 0.5;
                }

                svg {
                    width: 30px;
                    height: 30px;
                }
            }

            .page-layer__items {
                flex: inherit;
                max-width: inherit;

                //.project__content {
                //    display: block;
                //    flex: inherit;
                //    max-width: inherit;
                //    width: 100%;
                //    height: inherit;
                //    margin-bottom: 0;
                //}

                .project__content__image {
                    display: block;
                }

                .project__content__link {
                    max-width: 100%;
                    height: 100%;
                }
            }
        }

        &.page-layer--news {
            .page-layer__row {
                padding: 0 0 0 60px;
            }

            .page-layer__content__content {
                font-size: 1.188rem;
            }
        }
    }

    .page-layer--reference {
        .page-layer__partial--name {
            font-size: 1rem;
        }
    }
}

@media screen and (max-width: 991px) {
    .page-layer--banner {
        .page-layer--leftbar,
        .page-layer--rightbar {
            width: 30px;
        }
    }

    .page-layer--team .page-layer__partial--team {
        width: 100%;
        margin: 0 auto;
    }

    .page-layer--images {
        &.page-layer--two-images {
            column-gap: 35px;

            .page-layer__paragraph {
                -webkit-line-clamp: 1;
            }
        }

        &.page-layer--three-images {
            .page-layer__overlay {
                padding: 35px 30px;
            }

            .page-layer__paragraph {
                display: none;
            }
        }
    }

    .page-layer--collection {
        padding: 80px 0 85px 0;

        &.page-layer--news {
            .page-layer__row {
                display: block;
                padding-left: 0;
            }

            .page-layer__content {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0 60px;
                margin-bottom: 50px;
            }

            .page-layer__items {
                flex: inherit;
                max-width: inherit;
                padding: 0;
                margin: 0;
                display: block;

                .slick-slide {
                    margin: 0 15px;
                }

                .slick-list {
                    padding: 0 60px;
                    margin-right: -15px;
                    margin-left: -15px;
                }
            }
        }

        &.page-layer--projects {
            padding-top: 20px;
            padding-bottom: 50px;

            &:first-child {
                padding-top: 70px;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    .home {
        .page-layer--collection {
            &.page-layer--news {
                padding: 90px 0 90px 0;
            }
        }
    }

    .page-layer--reference {
        gap: 35px;
        padding: 70px;
    }
}

@media screen and (max-width: 767px) {
    .page-layer--intro-columns {
        .intro-column {
            gap: 15px;
        }
    }

    .page-layer--projects {
        .slick-list {
            padding: 0 30px;
        }

        .slick-slide {
            margin: 0 13px;
        }
    }

    .page-layers {
        row-gap: 40px;

        &:last-child {
            padding-bottom: 40px;
        }
    }

    /* Remove gap with min margins for certain layers */
    .page-layer--collection,
    .page-layer--cta {
        + .page-layer--collection,
        + .page-layer--cta {
            margin-top: -50px;
        }
    }

    .page-layer--intro {
        flex-direction: column;
    }

    .page-layer--columns {
        flex-direction: column;

        &.page-layer--two-columns {
            .text__reveal {
                flex-direction: column;
            }
        }
    }

    .page-layer--images {
        row-gap: 35px;

        .page-layer__overlay {
            padding: 35px 30px;
        }

        &.page-layer--two-images {
            flex-direction: column;

            .page-layer__partial--image {
                max-width: 80%;

                &:nth-of-type(1) {
                    align-self: flex-start;
                }

                &:nth-of-type(2) {
                    align-self: flex-end;
                }
            }
        }

        &.page-layer--three-images {
            column-gap: 0;
            overflow-x: hidden;
            margin-right: -30px;
            margin-left: -30px;

            .slick-list {
                width: calc(100% + 20px);
                margin-right: -10px;
                margin-left: -10px;
            }

            .slick-slide {
                margin-right: 10px;
                margin-left: 10px;

                img {
                    border-radius: 20px;
                }
            }
        }

        &.page-layer--one-image,
        &.page-layer--two-images,
        &.page-layer--three-images {
            .page-layer__title {
                -webkit-line-clamp: 3;
                margin-bottom: 10px;
            }

            .page-layer__paragraph {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                margin-bottom: 10px;
            }
        }
    }

    .page-layer--team .page-layer__partial--team .reveal__wrap {
        max-width: 100%;
    }

    .page-layer--team {
        .page-layer__partial--team {
            .team-member {
                width: 544px;
                max-width: 100%;
                margin: auto;
            }
        }
    }

    .page-layer__partial--team {
        flex-direction: column;
        align-items: center;
        row-gap: 30px;
    }

    .page-layer--pinkblock {
        padding: 30px 40px 20px 40px;
    }

    .page-layer--cta {
        gap: 35px;
        padding: 100px 30px 80px;
    }

    .page-layer--collection {
        overflow: hidden;

        &.page-layer--news {
            padding: 0;
            margin: 0 0 60px 0;

            .page-layer__content {
                padding: 0 30px;
            }

            .page-layer__items {
                .slick-slide {
                    margin: 0 15px;
                }

                .slick-list {
                    padding: 0 45px 0 30px;
                    margin-right: -15px;
                    margin-left: -15px;
                }
            }

            .block__img__inner {
                border-radius: 30px;

                img {
                    border-radius: 30px;
                }
            }
        }

        &.page-layer--projects {
            padding-top: 50px;
            padding-bottom: 50px;

            &:first-child {
                padding-top: 70px;
            }

            &:last-child {
                padding-bottom: 0;
            }

            .slick-track {
                display: flex;
            }

            .reveal__wrap {
                height: 100%;
            }

            .page-layer__items {
                flex: inherit;
                max-width: inherit;
                display: block;
                margin: 0 -38px 0 -38px;

                .slick-slide {
                    height: inherit;
                    margin-left: 8px;
                    margin-right: 8px;

                    > div {
                        height: 100%;
                    }
                }

                .project__content {
                    display: block;
                    flex: inherit;
                    max-width: inherit;
                    width: 100%;
                    height: 100%;
                    margin-bottom: 0;
                    padding: 0;
                }

                .project__content__link {
                    max-width: 100%;
                    height: 100%;
                }

                .project__content__image {
                    display: block;
                }
            }
        }
    }

    .home {
        .page-layer--collection {
            &.page-layer--news {
                padding: 50px 0 51px 0;
            }
        }
    }

    .page-layer--reference {
        .page-layer__partial--title {
            font-size: 1.25rem;
        }
    }
}

@media screen and (max-width: 575px) {
    .page-layer--reference {
        gap: 20px;
        padding: 35px;
    }

    .page-layer--images {
        &.page-layer--one-image,
        &.page-layer--two-images,
        &.page-layer--three-images {
            .page-layer__title {
                -webkit-line-clamp: 2;
            }

            .page-layer__paragraph {
                display: -webkit-box;
                -webkit-line-clamp: 2;
            }
        }
    }
}

@media screen and (max-width: 374px) {
    .page-layer--images {
        &.page-layer--one-image,
        &.page-layer--two-images,
        &.page-layer--three-images {
            .page-layer__title {
                -webkit-line-clamp: 1;
            }

            .page-layer__paragraph {
                display: -webkit-box;
                -webkit-line-clamp: 1;
            }
        }
    }
}
