@import "~bootstrap/scss/bootstrap";

@media (min-width: 1200px) {
    .container {
        max-width: 1920px;
    }

    .container-fluid {
        max-width: 1920px;
    }

    .container-fluid-xl {
        max-width: 1920px;
    }

    .container-fluid--lg {
        max-width: 1710px;
    }

    .container-fluid--md {
        max-width: 1350px;
    }

    .container-fluid--sm {
        max-width: 1070px;
    }
}
