.reference-page {
    .reference-page__title {
        @extend h1;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @extend h1;
        }

        text-align: center;
        margin-bottom: 30px;
    }

    .intro__layer {
        padding-bottom: 70px;
    }
}

.reference-page__items-container {
    padding-bottom: 160px;
}

.reference-list__title {
    font-size: 3.75rem;
    margin-bottom: 30px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 3.75rem;
    }
}

.reference-page__items {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    position: relative;

    &--smaller {
        grid-template-columns: repeat(3, 1fr);

        .reference-page__line {
            grid-column: span 3;
        }
    }

    .reference-page__line {
        grid-column: span 5;
        position: absolute;
        background-color: $white;
        z-index: 5;

        &--top {
            top: -2px;
            left: 0;
            width: 100%;
            height: 4px;
        }

        &--bottom {
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 4px;
        }

        &--right {
            right: -2px;
            top: 0;
            height: 100%;
            width: 4px;
        }

        &--left {
            left: -2px;
            top: 0;
            height: 100%;
            width: 4px;
        }
    }

    .reference__item {
        border-right: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;
    }
}

.reference-page__intro {
    font-size: 1.5rem;
}

.reference__link {
    font-size: 1.188rem;
    position: relative;
    text-decoration: underline;

    &:hover {
        color: $body-font-color;
        text-decoration: underline;
    }
}

.reference-list__description {
    font-size: 1.5rem;
    margin-bottom: 30px;
}

.reference-list__icon {
    width: 48px;
    height: 48px;
    background-color: $primarycolor;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    border-radius: 100%;
    transition: all 225ms ease-in-out;

    svg {
        width: 16px;
    }
}

.reference__content {
    border-radius: 25px;
    box-shadow: 0 0 46px transparentize(#000, 0.88);
    padding: 40px 25px;
    text-align: center;
    top: 95%;
    left: -5%;
    width: 110%;
}

.reference__title {
    font-size: 1.75rem;
    display: block;
    color: $primarycolor;
    font-family: $headings-font-family;
    margin-bottom: 10px;
    line-height: 1.2;
}

.reference__quote {
    display: block;
    margin-bottom: 10px;
    font-size: 1.063rem;
    color: initial;
}

.reference-list__link {
    font-size: 1.313rem;
    text-decoration: underline;
    display: flex;
    gap: 30px;
    justify-content: flex-start;
    align-items: center;
    transition: all 225ms ease-in-out;

    &:focus,
    &:hover {
        color: $body-font-color;
        text-decoration: underline;

        .reference-list__icon {
            background-color: $body-font-color;
        }
    }
}

.reference__item {
    position: relative;

    .reference__content {
        visibility: hidden;
        opacity: 0;
        transform: translateY(-10%);
        position: absolute;
        transition: all 200ms ease-in-out;
        background-color: $white;
        z-index: 60;
    }

    &:focus-within .reference__content {
        visibility: visible;
        display: block;
        opacity: 1;
        transform: translateY(0);
    }

    &:hover {
        .reference__content {
            visibility: visible;
            display: block;
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.reference__button {
    width: 100%;
    border: 0;
    padding: 0;
    background-color: $white;

    &:focus,
    &:active {
        outline: 0;
    }
}

.reference__img {
    /* stylelint-disable */
    aspect-ratio: 2.43 / 1;
    /* stylelint-enable */

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;

    img {
        /* stylelint-disable */
        aspect-ratio: 2.43 / 1;
        /* stylelint-enable */

        object-fit: contain;
    }
}

@media screen and (max-width: 1699px) {
    .reference-list__description {
        font-size: 1.313rem;
    }

    .reference-page__intro {
        font-size: 1.313rem;
    }

    .reference-list__link {
        font-size: 1.25rem;
    }

    .reference-list__icon {
        width: 42px;
        height: 42px;
    }

    .reference-list__title {
        font-size: 3.125rem;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 3.125rem;
        }
    }
}

@media screen and (max-width: 1299px) {
    .reference__item {
        .reference__content {
            padding: 30px 25px;
            left: -10%;
            width: 120%;
        }
    }

    .reference__title {
        font-size: 1.5rem;
    }

    .reference__link {
        font-size: 1.125rem;
    }
}

@media screen and (max-width: 1199px) {
    .reference__title {
        font-size: 1.375rem;
    }

    .reference__quote {
        font-size: 1rem;
    }

    .reference__link {
        font-size: 1.063rem;
    }

    .reference-page__items-container {
        padding-bottom: 120px;
    }

    .reference-list__description {
        font-size: 1rem;
    }

    .reference-page__intro {
        font-size: 1rem;
    }

    .reference-list__link {
        font-size: 0.938rem;
    }

    .reference-list__icon {
        width: 38px;
        height: 38px;

        svg {
            width: 14px;
        }
    }

    .reference-list__title {
        font-size: 2.5rem;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 2.5rem;
        }
    }

    .reference-page__items {
        grid-template-columns: repeat(4, 1fr);

        &--smaller {
            grid-template-columns: repeat(3, 1fr);

            .reference-page__line {
                grid-column: span 3;
            }
        }

        .reference-page__line {
            grid-column: span 4;
        }
    }
}

@media screen and (max-width: 991px) {
    .reference-list__row {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .reference-list__title,
    .reference-list__description {
        margin-bottom: 25px;
    }

    .reference__item {
        .reference__content {
            display: none;
        }

        &:hover {
            .reference__content {
                display: none;
            }
        }
    }

    .reference__title {
        font-size: 1.5rem;
    }

    .reference__link,
    .reference__quote {
        font-size: 1rem;
    }

    .reference-page__items {
        grid-template-columns: repeat(3, 1fr);

        .reference-page__line {
            grid-column: span 3;
        }
    }

    .single__main--reference {
        order: 2;
    }

    .single__sidebar--reference {
        margin-top: 0;
        margin-bottom: 30px;
        order: 1;
    }

    .reference__singleimg {
        justify-content: flex-start;
    }

    .reference__person {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 767px) {
    .reference-page__items-container {
        padding-bottom: 60px;
    }

    .reference__title {
        font-size: 1.375rem;
    }

    .reference__link,
    .reference__quote {
        font-size: 0.938rem;
    }

    .reference-page__items {
        grid-template-columns: repeat(2, 1fr);

        .reference-page__line {
            grid-column: span 2;
        }
    }

    .reference__img {
        padding: 20px;
    }

    .reference__person {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 575px) {

}

@media screen and (max-width: 375px) {
    .reference-page__items {
        grid-template-columns: repeat(1, 1fr);

        .reference-page__line {
            grid-column: span 1;
        }
    }
}
