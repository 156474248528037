.btn {
    position: relative;
    padding: 8px 30px;
    border-radius: $border-radius;
    border: none;
    margin-right: 8px;
    color: #fff;
    font-size: 1.75rem;
    display: inline-flex;
    align-items: center;
    font-family: $font-secondary;
    gap: 12px;
    transition: all 300ms ease-in-out;

    svg {
        width: 19px;
        height: auto;
        color: $primarycolor;
        fill: $primarycolor;
        transition: all 300ms ease-in-out;
    }

    &:hover,
    &:active,
    &:focus {
        box-shadow: none !important;
        color: #fff;
        transition: all 300ms ease-in-out;
    }

    &:last-child {
        margin-right: 0;
    }
}

.btn--primary {
    background-color: transparent;
    color: $primarycolor;
    border: 2px solid $primarycolor;

    &:hover,
    &:active,
    &:focus {
        background-color: $primarycolor;
        color: #fff;
        border: 2px solid $primarycolor;

        svg {
            color: #fff;
            fill: #fff;
        }
    }
}

.btn--white {
    background-color: #fff;
    color: $primarycolor !important;
    border: 2px solid #fff;

    &:hover,
    &:active,
    &:focus {
        background-color: $primarycolor !important;
        color: #fff !important;

        svg {
            color: #fff;
            fill: #fff;
        }
    }
}

.btn--secondary {
    background-color: $secondarycolor;

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($secondarycolor, 5) !important;
    }
}

.btn--tertiary {
    background-color: $primarycolor;
    color: #fff;

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
        color: #fff;
    }
}

.btn--next {
    background-color: $primarycolor;
    padding-right: 38px;

    &::after {
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -9px;
        width: 7px;
        height: 18px;
        background-image: url('../../images/angle-right-regular-white.svg');
        background-size: 7px 18px;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
    }
}

.btn--prev {
    background-color: $primarycolor;
    padding-left: 40px;

    &::before {
        content: '';
        position: absolute;
        left: 17px;
        top: 50%;
        margin-top: -9px;
        width: 7px;
        height: 18px;
        background-image: url('../../images/angle-left-regular-white.svg');
        background-size: 7px 18px;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
    }
}

.btn--open {
    color: #fff;
    border: 1px solid #fff;
    background-color: transparent;
    transition: all 0.3s;

    &:hover,
    &:active,
    &:focus {
        background-color: transparent;
        color: #fff !important;
        border-color: #fff;
    }
}

.btn-danger {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    svg {
        width: 15px;
        height: 15px;
        margin-left: 10px;
    }
}

.btn--skip {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2147483647;

    &:not(:focus):not(:active) {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        white-space: nowrap;
        width: 1px;
    }
}

@media screen and (max-width: 1199px) {
    .btn {
        font-size: 1.375rem;
    }

    .btn svg {
        width: 16px;
    }
}

@media screen and (max-width: 991px) {
    .btn {
        font-size: 1.125rem;
    }
}
