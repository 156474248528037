.not-found__page {
    position: relative;
    text-align: center;

    .intro__layer {
        position: relative;
        z-index: 1;
    }

    .btn {
        margin-top: 30px;
    }
}

.not-found__image {
    border-radius: 30px;
}

.not-found__layer {
    padding-top: 420px;
    padding-bottom: 160px;
}

.error404 {
    .footer {
        opacity: 1 !important;
        visibility: initial !important;
    }
}

.not-found__404 {
    position: absolute;
    top: 0;
    left: 50%;
    opacity: 0.05;
    transform: translateX(-50%) translateY(-40%);
    font-family: $graphik-bold;
    font-size: 590px;
    line-height: 1;
}

.not-found__button {
    padding: 12px 40px;
    gap: 15px;
}

.not-found__label {
    margin-top: -5px;
}

.not-found__title {
    text-align: center;
    margin-bottom: 40px;
}

@media screen and (max-width: 1199px) {
    .not-found__layer {
        padding-top: 320px;

        .btn {
            margin-top: 80px;
        }
    }

    .not-found__title {
        font-size: 3.75rem;
    }

    .not-found__404 {
        font-size: 320px;
    }

    .not-found__label {
        margin-top: -3px;
    }
}

@media screen and (max-width: 991px) {
    .not-found__title {
        font-size: 2.5rem;
    }

    .not-found__button {
        padding: 8px 30px;
    }

    .not-found__label {
        margin-top: -4px;
    }
}

@media screen and (max-width: 767px) {
    .not-found__404 {
        font-size: 240px;
    }
}

@media screen and (max-width: 575px) {
    .not-found__button {
        padding: 8px 25px;
    }

    .not-found__404 {
        font-size: 180px;
    }
}

@media screen and (max-width: 375px) {
    .not-found__404 {
        font-size: 130px;
    }
}
