.select2-container--default .select2-selection--single {
    border: 1px solid #d5d5d5;
    padding: 28px 35px;
    border-radius: 8px;
    font-size: 1.188rem;
    height: auto;
}

.gfield {
    margin-bottom: 15px;

    &::before {
        display: none;
    }

    input,
    select,
    textarea {
        background-color: #fff;
        box-shadow: 0 0 0 transparent;
        border-radius: 8px;
        min-height: 42px;
        border: 1px solid #d5d5d5;
        padding: 15px 24px;
        width: 100%;
        font-size: 1.188rem;

        &:focus {
            border-radius: 8px;
        }

        &::placeholder {
            color: $body-font-color;
        }
    }

    textarea {
        height: auto;
    }

    .ginput_container_date {
        position: relative;

        input {
            padding-left: 57px;
        }

        &::before {
            content: '';
            position: absolute;
            left: 1px;
            top: 1px;
            width: 41px;
            height: calc(100% - 2px);
            background-color: #eee;
            border-right: 1px solid #eee;
            border-radius: 8px 0 0 8px;
            background-image: url('../../svg/calendar-alt-regular.svg');
            background-size: 15px 16px;
            background-position: center;
            background-repeat: no-repeat;
            pointer-events: none;
        }
    }

    .ginput_container_fileupload {
        position: relative;

        input {
            padding-right: 57px;
        }

        &::before {
            content: '';
            position: absolute;
            right: 1px;
            top: 1px;
            width: 41px;
            height: calc(100% - 2px);
            background-color: #ececec;
            border-left: 1px solid #eee;
            border-radius: 0 $border-radius $border-radius 0;
            background-image: url('../../svg/file-alt-solid.svg');
            background-size: 12px 16px;
            background-position: center;
            background-repeat: no-repeat;
            pointer-events: none;
        }

        .validation_message {
            display: none;
        }
    }

    &.gfield_error {
        textarea,
        input,
        .select2-selection {
            border-color: #ef5350;
            outline: solid 1px #ef5350;
        }
    }
}

.gform_submission_error {
    font-size: 1.125rem;
    color: #ef5350;
}

.gform_description {
    margin-bottom: 23px;
    display: block;
}

.gfield_label {
    font-family: 'Fredoka-Bold';
    font-weight: 700;
    font-size: 1.125rem;
    margin-bottom: 10px;
}

.gfield_description {
    font-family: 'Fredoka-Bold';
    font-size: 1.125rem;
}

.gfield_checkbox {
    padding: 0;
    margin: 0;
    list-style: none;

    > li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;

        label {
            margin: 0;
            display: flex;
            align-items: flex-start;
            position: relative;
            cursor: pointer;
            font-weight: 400;

            &::before,
            &::after {
                content: " ";
                display: inline-block;
            }

            &::before {
                width: 15px;
                min-width: 15px;
                height: 15px;
                min-height: 15px;
                border: 1px solid #d5d5d5;
                border-radius: 3px;
                margin-top: 5px;
                margin-right: 15px;
            }
        }

        input {
            opacity: 0;
            width: 0;
            visibility: hidden;
            position: absolute;

            &:checked + label {
                &::after {
                    content: "";
                    width: 12px;
                    height: 9px;
                    background-image: url("../../images/checkbox-solid.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 12px 9px;
                    position: absolute;
                    left: 2px;
                    top: 8px;
                }
            }
        }
    }
}

.clear-multi {
    display: flex;
    margin: 0 -10px;

    > * {
        padding: 0 10px;
    }
}

.ginput_container_list {
    table {
        width: 100%;
    }

    tbody {
        display: flex;
        flex-direction: column;

        > tr {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            .gfield_list_cell {
                width: 100%;
            }

            .gfield_list_icons {
                display: flex;
                align-items: center;
                justify-content: center;

                > a {
                    margin: 0 3px;
                }
            }
        }
    }
}

.ginput_container_select {
    position: relative;

    &::after {
        content: "";
        background-image: url("../../images/angle-down-regular.svg");
        background-repeat: no-repeat;
        background-size: 10px 16px;
        background-position: center;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 16px;
        display: none;
    }

    .gfield_select {
        appearance: none;
        padding-top: 7px;

        &::-ms-expand {
            display: none; /* remove default arrow in IE 10 and 11 */
        }
    }
}

.gfield_time_ampm {
    min-width: 85px;
    font-size: 0.875rem;
}

.ginput_container_name {
    display: flex;
    margin: 0 -15px;

    > span {
        padding: 0 15px;
        flex: 0 0 50%;
        max-width: 50%;
    }

    label {
        display: block;
        order: 0;
    }
}

.ginput_container_post_image {
    input {
        padding: 4px;
    }
}

.ginput_container_fileupload {
    > input {
        padding: 4px;
    }
}

.ginput_container_address {
    > span {
        margin-bottom: 15px;
        display: block;

        > label {
            display: block;
            margin-top: 4px;
        }
    }
}

.gfield_contains_required {
    > .gfield_label {
        > .gfield_required {
            margin-left: 5px;
            color: #ef5350;
        }
    }
}

.ginput_container_consent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: -15px;

    a {
        text-decoration: underline;
        color: #138e9d;
    }

    > input {
        width: auto;
        height: 23px;
        margin-right: 15px;
    }

    > label {
        margin-bottom: 0;
    }
}

.gfield_radio {
    padding: 0;
    margin: 0;
    list-style: none;

    > li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;

        label {
            margin: 0;
            display: flex;
            align-items: flex-start;
            position: relative;
            cursor: pointer;
            font-weight: 400;

            &::before,
            &::after {
                content: "";
                display: inline-block;
            }

            &::before {
                width: 15px;
                min-width: 15px;
                height: 15px;
                min-height: 15px;
                border: 1px solid rgba(83, 83, 83, 0.4);
                border-radius: 8px;
                margin-top: 5px;
                margin-right: 15px;
            }
        }

        input {
            opacity: 0;
            width: 0;
            visibility: hidden;
            position: absolute;

            &:checked + label {
                &::after {
                    content: "";
                    width: 9px;
                    height: 9px;
                    background-color: $primarycolor;
                    border-radius: 50%;
                    position: absolute;
                    left: 3px;
                    top: 8px;
                }
            }
        }
    }
}

.ginput_container_multiselect {
    select {
        height: auto;
    }
}

.gform_required_legend {
    display: none;
}

.page-template-contact {
    .gform_fields {
        margin: 0 -15px;
    }

    .gfield {
        input,
        select,
        textarea {
            padding: 28px 35px;
        }
    }
}

ul.gform_fields {
    margin: 0 -15px;
}

.gform_fields {
    padding: 0;
    list-style: none;
    display: flex;
    column-gap: 16px;
    flex-wrap: wrap;

    .gfield--width-full {
        width: 100%;
    }

    .gfield--width-half {
        width: 100%;

        @media (min-width: 768px) {
            width: calc(50% - 8px);
        }
    }

    > li {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 15px;

        &.gform_hidden {
            height: 0 !important;
            overflow: hidden !important;
            margin: 0 !important;
            padding: 0 !important;
            position: absolute !important;
        }

        &.full {
            flex: auto;
            width: 100%;
            max-width: 100%;
        }
    }
}

.validation_error {
    color: #ef5350;
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0 0 15px;
}

.validation_message {
    position: relative;
    display: flex;
    color: #ef5350;
    font-size: 1rem;
    font-weight: 500;
    margin: 7px 0 0;
    align-items: center;

    &::after {
        content: '';
        position: relative;
        margin: 0 0 0 auto;
        width: 14px;
        height: 12px;
        background-image: url('../../images/exclamation-triangle-solid-red.svg');
        background-size: 14px 12px;
    }
}

.gform_button {
    margin: 15px 0 0;
    padding: 6px 30px 8px 65px;

    &:hover,
    &:active,
    &:focus {
        background: $primarycolor;
        color: $white;
        transition: all 300ms ease-in-out;

        &::before {
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' style='color: %23ffffff;' focusable='false' data-prefix='far' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M501.6 4.186c-7.594-5.156-17.41-5.594-25.44-1.063L12.12 267.1C4.184 271.7-.5037 280.3 .0431 289.4c.5469 9.125 6.234 17.16 14.66 20.69l153.3 64.38v113.5c0 8.781 4.797 16.84 12.5 21.06C184.1 511 188 512 191.1 512c4.516 0 9.038-1.281 12.99-3.812l111.2-71.46l98.56 41.4c2.984 1.25 6.141 1.875 9.297 1.875c4.078 0 8.141-1.031 11.78-3.094c6.453-3.625 10.88-10.06 11.95-17.38l64-432C513.1 18.44 509.1 9.373 501.6 4.186zM369.3 119.2l-187.1 208.9L78.23 284.7L369.3 119.2zM215.1 444v-49.36l46.45 19.51L215.1 444zM404.8 421.9l-176.6-74.19l224.6-249.5L404.8 421.9z'%3E%3C/path%3E%3C/svg%3E%0A");
            transition: all 300ms ease-in-out;
        }
    }

    &::before {
        position: absolute;
        top: 0;
        left: 30px;
        width: 20px;
        height: 100%;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' style='color: %23ff006c;' focusable='false' data-prefix='far' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M501.6 4.186c-7.594-5.156-17.41-5.594-25.44-1.063L12.12 267.1C4.184 271.7-.5037 280.3 .0431 289.4c.5469 9.125 6.234 17.16 14.66 20.69l153.3 64.38v113.5c0 8.781 4.797 16.84 12.5 21.06C184.1 511 188 512 191.1 512c4.516 0 9.038-1.281 12.99-3.812l111.2-71.46l98.56 41.4c2.984 1.25 6.141 1.875 9.297 1.875c4.078 0 8.141-1.031 11.78-3.094c6.453-3.625 10.88-10.06 11.95-17.38l64-432C513.1 18.44 509.1 9.373 501.6 4.186zM369.3 119.2l-187.1 208.9L78.23 284.7L369.3 119.2zM215.1 444v-49.36l46.45 19.51L215.1 444zM404.8 421.9l-176.6-74.19l224.6-249.5L404.8 421.9z'%3E%3C/path%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: center;
        transition: all 300ms ease-in-out;
    }
}

.gform_ajax_spinner {
    position: relative;
    top: 6px;
    left: 10px;
    width: 30px;
    height: 30px;
}

#ui-datepicker-div {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: (0 3px 5px 0 rgba(50, 50, 50, 0.75));
    display: none;
    padding: 20px;
    width: 300px;

    .ui-icon {
        color: transparent;
        cursor: pointer;
        font-size: 0;
    }

    .ui-icon::before {
        font-size: 1.125rem;
    }

    .ui-datepicker-prev {
        float: left;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .ui-icon::before {
            content: '';
            background-image: url('../../images/angle-left-regular.svg');
            float: left;
            background-size: 9px;
            width: 10px;
            height: 19px;
            background-repeat: no-repeat;
            position: relative;
        }

        &.ui-state-disabled {
            display: none;
        }
    }

    .ui-datepicker-next {
        float: right;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .ui-icon::before {
            content: '';
            background-image: url('../../images/angle-right-regular.svg');
            float: right;
            background-size: 9px;
            width: 10px;
            height: 19px;
            background-repeat: no-repeat;
            position: relative;
        }

        &.ui-state-disabled {
            display: none;
        }
    }

    .ui-datepicker-header {
        display: block;
        float: left;
        width: 100%;
        margin-top: 12px;
    }

    .ui-datepicker-title {
        select {
            float: left;
            width: 70%;
        }

        .ui-datepicker-month {
            margin: 0 5% 6px;
            border-radius: 4px;
            padding: 3px;
            border: 1px solid #cfcfcf;
        }

        .ui-datepicker-year {
            margin: 0 15% 6px;
            border-radius: 4px;
            padding: 3px;
            border: 1px solid #cfcfcf;
        }
    }

    .ui-datepicker-today {
        background-color: #e6eef1;
        border-radius: 4px;

        a {
            color: #2a4982;
        }
    }

    table {
        width: 100%;

        td,
        th {
            text-align: center;
        }

        td {
            a {
                display: block;
                padding: 5px;
                border-radius: 4px;

                &:hover {
                    background-color: $primarycolor;
                    color: #fff;
                    text-decoration: none;
                }

                &.ui-state-active {
                    background-color: $primarycolor;
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .gform_fields > li {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
