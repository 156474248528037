@font-face {
    font-family: 'Graphik-Regular';
    src: url('../../fonts/Graphik-Regular.eot');
    src: url('../../fonts/Graphik-Regular.eot?#iefix') format('embedded-opentype'), url('../../fonts/Graphik-Regular.svg#Graphik-Regular') format('svg'), url('../../fonts/Graphik-Regular.ttf') format('truetype'), url('../../fonts/Graphik-Regular.woff') format('woff'), url('../../fonts/Graphik-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik-Bold';
    src: url('../../fonts/Graphik-Bold.eot');
    src: url('../../fonts/Graphik-Bold.eot?#iefix') format('embedded-opentype'), url('../../fonts/Graphik-Bold.svg#Graphik-Bold') format('svg'), url('../../fonts/Graphik-Bold.ttf') format('truetype'), url('../../fonts/Graphik-Bold.woff') format('woff'), url('../../fonts/Graphik-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Fredoka-Regular';
    src: url('../../fonts/Fredoka-Regular.eot');
    src: url('../../fonts/Fredoka-Regular.eot?#iefix') format('embedded-opentype'), url('../../fonts/Fredoka-Regular.svg#Fredoka-Regular') format('svg'), url('../../fonts/Fredoka-Regular.ttf') format('truetype'), url('../../fonts/Fredoka-Regular.woff') format('woff'), url('../../fonts/Fredoka-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Fredoka-SemiBold';
    src: url('../../fonts/Fredoka-SemiBold.eot');
    src: url('../../fonts/Fredoka-SemiBold.eot?#iefix') format('embedded-opentype'), url('../../fonts/Fredoka-SemiBold.svg#Fredoka-SemiBold') format('svg'), url('../../fonts/Fredoka-SemiBold.ttf') format('truetype'), url('../../fonts/Fredoka-SemiBold.woff') format('woff'), url('../../fonts/Fredoka-SemiBold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Fredoka-Bold';
    src: url('../../fonts/Fredoka-Bold.eot');
    src: url('../../fonts/Fredoka-Bold.eot?#iefix') format('embedded-opentype'), url('../../fonts/Fredoka-Bold.svg#Fredoka-Bold') format('svg'), url('../../fonts/Fredoka-Bold.ttf') format('truetype'), url('../../fonts/Fredoka-Bold.woff') format('woff'), url('../../fonts/Fredoka-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
