.contact-member {
    position: fixed;
    right: 0;
    bottom: 60px;
    z-index: 100;
    display: flex;
    flex-direction: column;
}

html.no-touchevents {
    .contact-member {
        &:focus-within,
        &:hover {
            .contact-member__content {
                transform: translateY(-50%) translateX(0);
                margin-left: 0;
            }
        }
    }
}

html.touchevents {
    .contact-member {
        &.is-active {
            .contact-member__content {
                transform: translateY(-50%) translateX(0);
                margin-left: 0;
            }
        }
    }
}

.contact-member__content {
    position: absolute;
    top: 14px;
    right: 0;
    transform: translateY(-50%) translateX(100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 140px;
    padding: 30px 35px 40px 45px;
    margin-left: 36px;
    background-color: #fff;
    border-radius: 50px 0 0 50px;
    box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.16);
    transition: all 0.15s ease-in-out;
}

.contact__member__bottom {

}

.contact-member__title,
.contact-member__subtitle,
.contact-member__detail a {
    white-space: nowrap;
}

.contact-member__title {
    font-family: $fredoka-bold;
    font-size: 1.563rem;
}

.contact-member__subtitle {
    font-size: 1rem;
    line-height: 1;
    margin-bottom: 15px;
}

.contact-member__link {
    color: $body-font-color;

    &:hover {
        color: $primarycolor;
    }
}

.contact-member__details {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
}

.contact-member__detail {
    margin-right: 13px;

    a {
        color: #000;
        font-size: 1.125rem;
        font-family: $fredoka-regular;

        &:focus,
        &:hover {
            color: $primarycolor;
        }

        svg {
            color: $primarycolor;
            margin-right: 7px;
            margin-top: -4px;
        }
    }

    &::before {
        display: none;
    }

    svg {
        width: 19px;
        height: 19px;
    }
}

.contact-member__image {
    position: relative;
    margin-right: 20px;
    z-index: 1;

    img {
        display: block;
        width: 105px;
        height: 105px;
        object-fit: cover;
        border-radius: 50%;
    }
}

@media screen and (max-width: 1199px) {
    .contact-member {
        top: auto;
        bottom: 40px;
        transform: none;
    }
}

@media screen and (max-width: 767px) {
    .contact-member {
        bottom: 45px;
    }

    .contact-member__content {
        min-height: 105px;
        padding: 18px 22px 19px 30px;
        border-radius: 20px 0 0 20px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
    }

    .contact-member__title {
        font-size: 1.188rem;
    }

    .contact-member__subtitle {
        font-size: 0.875rem;
    }

    .contact-member__detail {
        margin-right: 10px;

        a {
            font-size: 0.875rem;
        }

        svg {
            width: 16px;
            height: 16px;
        }
    }

    .contact-member__image {
        top: 10px;

        img {
            width: 75px;
            height: 75px;
        }
    }
}
