$revealDistance: 20%;

.reveal__wrap {
    display: inherit;

    &--full {
        height: 100%;
    }

    &--flex {
        display: flex;
    }

    &--inline-block {
        display: inline-block;
    }

    .image__reveal {
        transform: scale(1.2);
        opacity: 0;
        display: inherit;
    }

    .text__reveal {
        transform: translateY($revealDistance);
        opacity: 0;
        display: inherit;
    }

    .block__reveal {
        transform: translate(0, $revealDistance);
        display: inline-block;
        width: 100%;
        height: 100%;
        opacity: 0;

        &--top {
            transform: translate(0, -$revealDistance);
        }

        &--right {
            transform: translate($revealDistance, 0);
        }

        &--bottom {
            transform: translate(0, $revealDistance);
        }

        &--left {
            transform: translate(-$revealDistance, 0);
        }

        &--short {
            transform: translate(0, 25%);
            transition-duration: 100ms;
        }

        &--half {
            transform: translate(0, 50%);
        }

        &--full {
            transform: translate(0, 100%);
        }
    }
}
