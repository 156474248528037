.single-diensten {
    .single__content {
        padding: 157px 0 0 0;
    }
}

.diensten {
    margin-bottom: 107px;

    &__banner {
        border-radius: 70px;
        overflow: hidden;
        margin-bottom: 93px;

        img {
            object-fit: cover;
            width: 100%;
            height: 530px;
        }
    }

    &__content {
        margin-bottom: 42px;

        h1 {
            margin-bottom: 27px;
        }
    }

    &__stamp {
        width: 250px;
        height: 250px;
        font-size: 1.625rem;
        font-family: 'Fredoka-Bold';
        text-transform: uppercase;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(-7.5deg);

        path {
            fill: $primarycolor;
        }

        &--text {
            position: absolute;
            text-align: center;
        }
    }
}

.subdiensten {
    background: #f8f8f8;
    padding-top: 70px;
    padding-bottom: 100px;
    margin-bottom: 20px;

    &__item {
        margin-top: 33px;
        margin-bottom: 33px;

        h4 {
            color: $primarycolor;
            font-size: 1.938rem;
            margin-bottom: 24px;
        }

        p {
            margin-bottom: 14px;
        }

        &:nth-child(2n + 2) {
            padding-left: 47px;
        }

        &:nth-child(2n + 1) {
            padding-right: 47px;
        }

        &__link {
            text-decoration: underline;

            &:focus,
            &:hover {
                color: #000;
                text-decoration: underline;
            }
        }
    }
}

.categories {
    background: #f0f0f0;
    border-radius: 10px;
    padding: 3px 45px 28px 45px;
}

.categories__title {
    margin-top: 26px;
}

.categories__list {
    list-style-type: none;
    padding: 0;
}

.categories__item {
    position: relative;
    display: block;
    font-size: 0.938rem;
    font-weight: 400;
    color: #505050;
    background: #d9d9d9;
    border-radius: $border-radius;
    text-transform: uppercase;
    padding: 10px 21px;
    margin-bottom: 11px;

    &:hover {
        &::after {
            right: 13px;
        }
    }

    &::after {
        content: '';
        position: absolute;
        right: 15px;
        margin-top: 5px;
        width: 6px;
        height: 18px;
        background: url('../../images/angle-right-regular.svg') no-repeat;
        background-size: 6px 16px;
        transition: all 0.3s;
    }
}

.service__intro {
    display: flex;
}

.service__intro__left {
    max-width: 50%;
    flex: 0 0 50%;
    padding-right: 50px;
}

.service__intro__right {
    max-width: 50%;
    flex: 0 0 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-top: 30px;
}

.service__list__items {
    display: flex;
    flex-wrap: wrap;
    max-width: 650px;
    gap: 10px 0;
    text-transform: lowercase;

    a {
        color: $body-font-color;

        &:hover,
        &:focus {
            color: $primarycolor;
        }
    }
}

.service__block {
    position: relative;
    display: flex;
    gap: 10%;
    padding-right: 10%;
    transition: initial;

    a {
        transition: initial;

        &:hover,
        &:focus {
            transition: initial;
        }
    }

    .service__block__left {
        opacity: 0;
        transform: translateX(-150px);
    }

    .service__block__right {
        opacity: 0;
        transform: translateX(-100px);
    }

    &:nth-child(even) {
        flex-direction: row-reverse;
        padding-right: 0;
        padding-left: 10%;

        .service__block__left {
            opacity: 0;
            transform: translateX(150px);
        }

        .service__block__right {
            opacity: 0;
            transform: translateX(100px);
        }
    }
}

.service__scroll__to {
    position: absolute;
    top: -100px;
}

.service__list__item {
    max-width: 50%;
    flex: 0 0 50%;
    font-size: 1.75rem;
    min-width: 200px;
    font-family: $font-secondary;
}

.service__list {
    padding-bottom: 195px;
}

.service__list__inner {
    display: flex;
    flex-direction: column;
    gap: 70px;
}

.service__block__left {
    width: 100%;
    max-width: 685px;

    img {
        border-radius: $block-radius;
    }
}

.service__block__right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.service__block__content {
    max-width: 685px;
    width: 100%;

    > .btn {
        text-transform: lowercase;
    }
}

.service__block__title {
    font-size: 3.438rem;
    font-family: $fredoka-bold;
    margin-bottom: 15px;
    display: block;
    color: $body-font-color;
}

.service__block__text {
    margin-bottom: 30px;
}

.service__block__list {
    margin-bottom: 40px;
    display: grid;
    gap: 15px 10px;
    grid-template-columns: repeat(2, 1fr);
    font-size: 1.125rem;
}

li.service__block__list__item {
    &::before {
        top: 8px;
    }
}

.page-layer--services__inner {
    padding: 75px 45px 75px 0;
}

.page-layer--services__inner__content {
    position: relative;
    z-index: 2;

    &--title {
        font-size: 2.5rem;
    }

    &--content {
        font-size: 1.313rem;
        margin-bottom: 29px;
    }

    &--link {
        text-decoration: underline;

        &:focus-within,
        &:hover {
            color: #000;
            text-decoration: underline;
        }
    }
}

.page-layer--services__odd {
    &:nth-child(4n + 1) .page-layer--services__inner {
        margin-right: 45px;

        &::after {
            content: "";
            width: 130%;
            height: 100%;
            position: absolute;
            right: 45px;
            top: 0;
            background: url(../../images/repeat-w-pink.svg);
            background-position: top right;
            z-index: 0;
        }

        &::before {
            content: "";
            width: 30%;
            height: 100%;
            position: absolute;
            right: calc(100% + 45px);
            top: 0;
            background: rgb(255, 255, 255);
            background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
            z-index: 1;
        }
    }

    &:nth-child(4n + 3) {
        padding-left: 45px;

        .page-layer--services__inner {
            padding-left: 45px;
            padding-right: 0;
        }
    }
}

.page-layer--services__even {
    &:nth-child(4n + 4) .page-layer--services__inner {
        padding-left: 45px;
        padding-right: 0;
        margin-left: 45px;

        &::after {
            content: "";
            width: 130%;
            height: 100%;
            position: absolute;
            left: 45px;
            top: 0;
            background: url(../../images/repeat-w-gray.svg);
            background-position: top left;
            z-index: 0;
        }

        &::before {
            content: "";
            width: 30%;
            height: 100%;
            position: absolute;
            left: calc(100% + 45px);
            top: 0;
            background: rgb(255, 255, 255);
            background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
            z-index: 1;
        }
    }

    &:nth-child(4n + 2) {
        padding-right: 45px;
    }
}

@media screen and (max-width: 1699px) {
    .service__block {
        padding-right: 0;
        gap: 50px;

        &:nth-child(even) {
            padding-left: 0;
        }
    }

    .service__list {
        padding-bottom: 115px;
    }

    .service__intro {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .service__intro__right {
        padding-top: 0;
        padding-left: 30px;
    }

    .service__intro__left {
        h1,
        h2,
        h3,
        h4,
        h5 {
            font-size: 3.25rem;
            line-height: 1.1;
        }
    }

    .service__content {
        padding: 280px 0 80px;
    }

    .service__block__title {
        font-size: 3.25rem;
    }
}

@media screen and (max-width: 1199px) {
    .service__intro__left {
        h1,
        h2,
        h3,
        h4,
        h5 {
            font-size: 2.125rem;
            line-height: 1.2;
        }
    }

    li.service__block__list__item {
        &::before {
            top: 12px;
        }
    }

    .service__block:first-child .service__block__left {
        transform: translateX(0) !important;
        opacity: 1 !important;
    }

    .service__block:first-child .service__block__right {
        transform: translateX(0) !important;
        opacity: 1 !important;
    }

    .service__block .service__block__left {
        transform: translateX(-100px);
    }

    .service__block:nth-child(even) .service__block__left {
        transform: translateX(100px);
    }

    .service__block__list {
        grid-template-columns: 100%;
        font-size: 1rem;
    }

    .service__content {
        padding: 200px 0 60px;
    }

    .service__list__item {
        font-size: 1.5rem;
        min-width: auto;
    }

    .service__list__items {
        max-width: 500px;
    }

    .service__block__title {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 991px) {
    .subdiensten {
        padding-top: 23.5px;
        padding-bottom: 43.5px;
        margin-bottom: 20px;

        h4 {
            margin-bottom: 14px;
            font-size: 1.688rem;
        }

        p {
            margin-bottom: 14px;
        }

        &__item {
            &:nth-child(2n + 2) {
                padding-left: 12px;
            }

            &:nth-child(2n + 1) {
                padding-right: 12px;
            }
        }
    }

    .page-layer--services__inner__content {
        position: relative;
        z-index: 2;

        &--title {
            font-size: 1.75rem;
        }

        &--content {
            font-size: 1.125rem;
        }

        &--link {
            text-decoration: underline;
        }
    }

    .page-layer--services__even {
        .page-layer--services__inner {
            padding: 35px 0 35px 0;
        }

        &:nth-child(4n + 2) {
            padding-right: 12px;
        }

        &:nth-child(4n + 4) > .page-layer--services__inner {
            padding: 35px 0 35px 0;
            margin-left: 0;

            &::before {
                display: none;
            }

            &::after {
                left: -30%;
            }
        }
    }

    .page-layer--services__odd {
        .page-layer--services__inner {
            padding: 35px 0 35px 0;
        }

        &:nth-child(4n + 1) > .page-layer--services__inner {
            padding: 35px 0 35px 0;
            margin-right: 0;

            &::before {
                display: none;
            }

            &::after {
                right: -30%;
            }
        }

        &:nth-child(4n + 3) > .page-layer--services__inner {
            padding-left: 0;
        }

        &:nth-child(4n + 3) {
            padding-left: 12px;
        }
    }

    .single__main--service {
        order: 0;
        margin-bottom: 20px;
    }

    .service__intro__left {
        padding-right: 20px;
    }

    .service__list__item {
        font-size: 1.25rem;
    }

    .service__block__list {
        gap: 8px 0;
        margin-bottom: 25px;
    }

    .service__block__text {
        margin-bottom: 25px;
    }

    .diensten {
        margin-bottom: 47px;

        &__banner {
            margin-bottom: 43px;
            border-radius: 20px;

            img {
                height: 250px;
            }
        }

        &__content {
            margin-bottom: 23px;
        }

        &__stamp {
            width: 150px;
            height: 150px;
            font-size: 1rem;
            margin-top: 50px;
        }
    }
}

@media screen and (max-width: 767px) {
    .single__main--service {
        margin-bottom: 0;
    }

    .service__list {
        padding-bottom: 70px;
    }

    .categories {
        padding: 1px 35px 28px 35px;
    }

    .service__intro {
        flex-direction: column;
    }

    .service__intro__left {
        padding: 0;
        max-width: 100%;
        flex: 0 0 100%;
        text-align: center;
    }

    .service__intro__right {
        position: relative;
        padding: 50px 0 0;
        max-width: 100%;
        flex: 0 0 100%;

        &::after {
            content: '';
            /* stylelint-disable */
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
            background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
            /* stylelint-enable */
            position: absolute;
            right: 0;
            top: 0;
            width: 90px;
            height: 100%;
            pointer-events: none;
        }
    }

    .service__list__items {
        max-width: 100%;
        flex-wrap: nowrap;
        gap: 0 35px;
        overflow: scroll;
        padding-bottom: 10px;
        padding-right: 60px;
    }

    .service__list__item {
        flex: 0 0 auto;
        max-width: 100%;
    }

    .service__block {
        gap: 30px;

        &:nth-child(even) {
            .service__block__left {
                transform: translateX(0) !important;
            }

            .service__block__right {
                transform: translateX(0) !important;
            }
        }
    }

    .service__block__right {
        transform: translateX(0) !important;
    }

    .service__block__left {
        max-width: 40%;
        transform: translateX(0) !important;
    }
}

@media screen and (max-width: 575px) {
    .service__block {
        flex-direction: column;
        gap: 20px;
    }

    .service__block:nth-child(even) {
        flex-direction: column;
    }

    .service__list__item {
        font-size: 1.125rem;
    }

    .service__block__left {
        max-width: 100%;
    }

    .service__list__inner {
        gap: 50px;
    }

    .service__block__list {
        gap: 8px 10px;
    }
}
