.front__intro {
    position: relative;
    min-height: 850px;
    display: flex;
    align-items: center;
    padding-top: 80px;

    &__inner {
        z-index: 1;
        position: relative;
    }

    .btn {
        font-size: 1.313rem;
        background: #f5f5f5;
        color: $primarycolor;
        padding: 20px 45px;
        line-height: 20px;

        &--tertiary svg path {
            fill: #fff;
        }

        &:hover,
        &.active {
            background: $primarycolor;
            color: #fff;
            padding-left: 45px;
        }
    }

    &__img {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 0;
    }

    [class^="content-"] {
        h2 {
            font-size: 3.75rem;
            margin-bottom: 37px;
        }

        p {
            font-size: 1.5rem;
            line-height: 39px;
        }
    }
}

.front__intro__buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    margin-top: 43px;
}

@media (max-width: 991px) {
    .front__intro {
        min-height: initial;
        margin-bottom: 80px;

        .btn {
            font-size: 1.125rem;
            padding: 20px 35px;
            line-height: 20px;
            height: 60px;

            &:hover,
            &.active {
                padding: 20px 35px;
            }
        }

        [class^="content-"] {
            h2 {
                font-size: 2.5rem;
                margin-bottom: 24px;
            }

            p {
                font-size: 1.313rem;
                line-height: 33px;
            }
        }
    }
}

@media (max-width: 767px) {
    .front__intro__buttons {
        gap: 10px;
        margin-top: 30px;
    }
}
