.menu__block {
    position: fixed;
    z-index: 9990;
    top: 0;
    overflow: hidden;
    left: 0;
    display: none;
    justify-content: space-between;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    pointer-events: none;
    opacity: 0;
    background-color: $primarycolor;
    flex-direction: column;
}

.menu {
    position: relative;
    z-index: 10050;
}

.menu__center__link {
    display: none;
}

.menu__layer1 {
    position: fixed;
    z-index: 9991;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    pointer-events: none;
    border-radius: 0;
    background-color: #000;
}

.menu__layer2 {
    position: fixed;
    z-index: 9992;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 0;
    pointer-events: none;
    border-radius: 0;
    background-color: $primarycolor;
}

.front__parent {
    .header__logo {
        .header__logo__white {
            opacity: 0;
        }
    }
}

.menu__top {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    min-height: 140px;
    max-height: 140px;
    height: 140px;
    width: 100%;

    .header__logo {
        opacity: 1;
        transition: all 150ms ease-in-out;

        &:hover,
        &:focus {
            opacity: 1;
            transition: all 150ms ease-in-out;
        }
    }

    > .container-fluid {
        max-width: 100%;
        padding: 0 72px 0 60px;
    }

    .header {
        opacity: 0;
        pointer-events: none;
    }
}

.menu__top__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.menu__center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    padding: 0 100px;

    ul {
        li {
            &::before {
                display: none;
            }
        }
    }
}

.menu__center__inner {
    width: 1550px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;
    padding-right: 120px;
    gap: 100px;
}

.menu__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;

    svg {
        width: 230px;
        height: 307px;
        margin-top: -75px;
        color: #67858a;
    }
}

.menu__bar {
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.menu__number {
    position: absolute;
    z-index: 1;
    font-family: $font-primary;
    font-size: 6.25rem;
    font-weight: 600;
    color: #fff;
}

.menu__image {
    width: 100%;
    height: 500px;
    opacity: 0.1;
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(1);
}

.menu__bar--left {
    .menu__number {
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
    }

    .menu__image {
        background-position: center right;
    }
}

.menu__bar--right {
    .menu__number {
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
    }

    .menu__image {
        background-position: center left;
    }
}

.menu__icon__spacer {
    display: block;
    width: 40px;
}

.menu__icon {
    svg {
        fill: transparent;
        width: 40px;
        height: auto;
        position: relative;
        top: -8px;
        transition: all 150ms ease-in-out;
    }
}

.menu__inner {
    padding: 0;
    z-index: 99;

    .nav {
        display: flex;
        flex-direction: column;

        .menu-item {
            a {
                display: flex;
                gap: 30px;
                flex-direction: row-reverse;
                justify-content: flex-end;
                align-items: center;
                padding: 0;
                transition: all 150ms ease-in-out;
                font-size: 2.188rem;
                color: #fff;
                line-height: 2.7;
                font-family: $fredoka-semi-bold;

                &:focus,
                &:hover {
                    color: $body-font-color;
                    transition: all 150ms ease-in-out;

                    &::after {
                        opacity: 1;
                        transition: all 150ms ease-in-out;
                    }

                    .menu__icon {
                        svg {
                            color: $body-font-color;
                            transition: all 150ms ease-in-out;
                        }
                    }
                }
            }

            [target="_blank"] {
                position: relative;

                &::after {
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg style='fill: %23000000;' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M488 0h-135.3c-13.25 0-25.09 7.906-30.19 20.16c-5.062 12.28-2.281 26.25 7.094 35.63l40.69 40.69L177.4 289.4c-12.5 12.5-12.5 32.75 0 45.25C183.6 340.9 191.8 344 200 344s16.38-3.125 22.62-9.375l192.9-192.9l40.69 40.69C462.5 188.7 470.8 192 479.3 192c4.219 0 8.469-.8125 12.56-2.5C504.1 184.4 512 172.6 512 159.3V24C512 10.75 501.3 0 488 0zM392 320c-13.25 0-24 10.75-24 24v112c0 4.406-3.594 8-8 8h-304c-4.406 0-8-3.594-8-8v-304c0-4.406 3.594-8 8-8h112C181.3 144 192 133.3 192 120S181.3 96 168 96h-112C25.13 96 0 121.1 0 152v304C0 486.9 25.13 512 56 512h304c30.88 0 56-25.12 56-56v-112C416 330.8 405.3 320 392 320z'/%3E%3C/svg%3E");
                    position: absolute;
                    left: calc(100% + 25px);
                    background-size: 20px;
                    width: 22px;
                    height: 22px;
                    background-repeat: no-repeat;
                    margin-top: -18px;
                    opacity: 0;
                    transition: all 150ms ease-in-out;
                }
            }
        }

        .current-menu-item {
            cursor: pointer;

            a {
                color: $body-font-color;
                pointer-events: none;
                transition: all 150ms ease-in-out;
            }

            .menu__icon {
                svg {
                    fill: $body-font-color;
                    transition: all 150ms ease-in-out;
                }
            }
        }
    }
}

.menu__center__left {
    .menu__inner .nav .menu-item a {
        font-size: 5.313rem;
        line-height: 1.6;
    }

    &:hover,
    &:focus {
        .current-menu-item {
            a {
                color: $body-font-color;
            }

            .menu__icon {
                svg {
                    fill: $body-font-color;
                }
            }

            &:hover,
            &:focus {
                a {
                    color: $body-font-color;
                    transition: all 150ms ease-in-out;
                }

                .menu__icon {
                    svg {
                        fill: $body-font-color;
                        transition: all 150ms ease-in-out;
                    }
                }
            }
        }
    }
}

.menu__center__right {
    .menu__inner .nav .menu-item a {
        font-family: $font-secondary;
    }

    &:hover,
    &:focus {
        .current-menu-item {
            a {
                color: #fff;
            }

            .menu__icon {
                svg {
                    fill: $secondarycolor;
                }
            }
        }
    }
}

.menu__social {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10vh;
}

.menu__social__item {
    margin: 0 10px;
}

.menu__layer__logo {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    pointer-events: none;
    opacity: 0;

    svg {
        width: 550px;
        height: 250px;
        opacity: 1;
    }
}

@media screen and (max-width: 1499px) {
    .menu__center__left .menu__inner .nav .menu-item a {
        font-size: 4.125rem;
    }

    .menu__inner .nav .menu-item a {
        font-size: 2rem;
        line-height: 2.5;
    }

    .menu__center__inner {
        padding: 0;
    }

    .menu__center {
        padding: 0 135px;
    }

    .menu__top > .container-fluid {
        padding: 0 60px;
    }

    .menu__layer__logo svg {
        max-width: 60%;
        max-height: 60%;
        position: relative;
        top: -40px;
        height: 160px;
    }
}

@media screen and (max-width: 1199px) {
    .menu__center__left .menu__inner .nav .menu-item a {
        font-size: 2.375rem;
    }

    .menu__inner .nav .menu-item [target="_blank"]::after {
        display: none;
    }

    .menu__icon__spacer,
    .menu__icon {
        display: none;
    }

    .menu__inner .nav .menu-item a {
        gap: 22px;
        font-size: 1.563rem;
        line-height: 2;
    }

    .menu__center__inner {
        max-width: 78%;
    }

    .menu__center {
        padding: 60px 30px 30px;
    }

    .menu__top > .container-fluid {
        padding: 0 30px;
    }

    .menu__top {
        min-height: 113px;
        max-height: 113px;
        height: 113px;
    }
}

@media screen and (max-width: 991px) {
    .menu__center__inner {
        max-width: 82%;
    }
}

@media screen and (max-width: 767px) {
    .menu__center__link {
        display: block;
        border-color: $white;
        color: $white;

        &:focus,
        &:hover {
            background: $white;
            color: $primarycolor;
        }
    }

    .menu__icon__spacer,
    .menu__icon {
        display: none;
    }

    .menu__center {
        padding: 120px 30px 30px;
    }

    .menu__center__inner {
        flex-direction: column;
        max-width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 30px;
    }
}

@media screen and (max-width: 380px) {
    .menu__center__left .menu__inner .nav .menu-item a {
        font-size: 2rem;
        line-height: 1.5;
    }

    .menu__inner .nav .menu-item a {
        font-size: 1.25rem;
        line-height: 1.8;
    }
}
