/**
* Some default classes for heading styling and are detached from
* the heading tags.
*/

.title,
.subtitle {
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
}

.title {
    font-size: $h1-font-size;

    &--large {
        font-size: calc(#{$h1-font-size} * 1.4);
    }

    &--small {
        font-size: calc(#{$h1-font-size} * 0.8);
    }
}

.subtitle {
    font-size: $h2-font-size;

    &--large {
        font-size: calc(#{$h2-font-size} * 1.4);
    }

    &--small {
        font-size: calc(#{$h2-font-size} * 0.8);
    }
}
