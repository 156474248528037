/* Project overview */
.project__page {
    .scroll__item {
        top: -80px;
    }
}

.project__page__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 90px;
    position: relative;
}

.project__content__toptitle {
    display: flex;
    justify-content: center;
    overflow: hidden;
    transform: translateY(33%);
    margin-top: -65px;

    img {
        width: 100%;
        max-width: 1515px;
    }
}

.project__content__link {
    position: relative;
    overflow: hidden;
    display: block;
    border-radius: 50px;
    /* stylelint-disable */
    aspect-ratio: 1.5 / 1;
    /* stylelint-enable */

    .project__content__image {
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            /* stylelint-disable */
            aspect-ratio: 1.5 / 1;
            /* stylelint-enable */
        }
    }

    .project__content__overlay {
        transition: all 300ms ease-in-out;
        padding: 45px 50px;
        position: absolute;
        width: 100%;
        height: 100%;
        color: $white;
        top: 0;
        left: 0;
        z-index: 5;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        background: linear-gradient(180deg, transparentize($black, 1) 0%, transparentize($black, 0.5) 100%);
    }

    .project__content__subject {
        font-size: 1.063rem;
        position: absolute;
        right: 35px;
        bottom: 55px;
        transform: rotate(-90deg) translateX(100%);
        transform-origin: bottom right;
    }

    .project__content__subtitle {
        font-size: 1.063rem;
    }

    .project__content__title {
        font-size: 1.75rem;
        line-height: 1.2;
        margin: 0;
        font-family: $fredoka-bold;
    }
}

/* Project detail */
.project__detail {
    .page-layers {
        position: relative;
        padding-top: 180px;
        background-color: #fff;
    }

    .intro__layer {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        height: 100vh;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;

        &.intro__layer--project {
            padding-top: 100px;
            padding-bottom: 100px;
        }

        &::before {
            position: absolute;
            inset: 0;
            background: rgba(0, 0, 0, 0.5);
            content: '';
        }

        .intro__headings {
            position: relative;
            z-index: 1;
            text-align: center;
        }

        .intro__title {
            margin: 0;
            color: #fff;
        }

        .intro__subtitle {
            font-size: 1.75rem;
            color: $primarycolor;
            font-family: $font-secondary;
        }
    }
}

@media screen and (max-width: 1699px) {
    .project__detail {
        .page-layers {
            padding-top: 160px;
        }
    }
}

@media screen and (max-width: 1539px) {
    .project__content__toptitle {
        padding-left: 30px;
    }
}

@media screen and (max-width: 1199px) {
    .project__content__link {
        .project__content__subject {
            font-size: 1rem;
            bottom: 40px;
        }

        .project__content__subtitle {
            font-size: 1rem;
        }

        .project__content__title {
            font-size: 1.5rem;
        }
    }

    .project__detail {
        .page-layers {
            padding-top: 90px;
        }
    }

    .project__page__items {
        padding-bottom: 40px;
    }

    .project__detail .intro__layer .intro__subtitle {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 991px) {
    .project__content__link {
        .project__content__subject {
            bottom: 50px;
        }
    }

    .single__main--projects {
        order: 1;
        margin-bottom: 0;
    }

    .single__sidebar--projects {
        order: 0;
        margin-bottom: 30px;
        margin-top: 0;
    }
}

@media screen and (max-width: 767px) {
    .single__sidebar--projects {
        order: 0;
        margin-bottom: 20px;
    }

    .project__page__items {
        grid-template-columns: 100%;
        gap: 50px;
    }

    .project__content__link {
        .project__content__overlay {
            padding: 24px 32px;
        }

        .project__content__subject {
            font-size: 0.938rem;
            right: 25px;
            bottom: 45px;
        }

        .project__content__subtitle {
            font-size: 0.938rem;
        }

        .project__content__title {
            font-size: 1.375rem;
        }
    }
}

@media screen and (max-width: 575px) {
    .project__page__items {
        gap: 30px;
    }
}

@media screen and (max-width: 475px) {
    .project__content__link {
        .project__content__subject {
            display: none;
        }
    }
}

@media screen and (max-width: 375px) {
    .project__detail {
        .page-layers {
            padding-top: 60px;
        }
    }
}
