.footer {
    background: #000;
    z-index: 55;
    color: #fff;
    min-height: 700px;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    position: relative;
    margin-top: 170px;

    &__menu--one,
    &__menu--two {
        margin-bottom: 60px;
    }

    &__menu--one,
    &__menu--two,
    &__menu--three,
    &__menu--four {
        padding-right: 30px;
    }

    &__menu__title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
            color: #fff;
            font-size: 1.313rem;

            a {
                color: $white;

                &:focus-within,
                &:hover {
                    color: $primarycolor;
                }
            }
        }

        &__toggler {
            color: $primarycolor;
            background: none;
            border: none;
            margin-bottom: 20px;
            transition: 0.3s ease-in-out transform;

            &.active {
                transform: rotate(180deg);
            }

            svg {
                width: 11px;
            }
        }
    }

    .footernav {
        line-height: 33px;

        a {
            color: #fff;
            font-size: 0.938rem;

            &:focus-within,
            &:hover {
                color: $primarycolor;
            }
        }
    }

    &__holder {
        margin-top: 150px;

        li {
            padding-left: 0;
        }
    }

    &__img {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 765px;
        opacity: 0.7;
        z-index: -1;
    }

    &__address {
        font-size: 1.063rem;
        line-height: 33px;
        padding-right: 30px;

        a {
            color: #fff;

            &:focus-within,
            &:hover {
                color: $primarycolor;
            }
        }

        h4 {
            color: #fff;
            font-size: 1.313rem;
        }

        li::before {
            display: none;
        }
    }

    &__copyright {
        margin-top: 70px;
        margin-bottom: 50px;
        font-size: 0.813rem;
        opacity: 0.6;

        a {
            color: #fff;
            display: inline-block;

            &:focus-within,
            &:hover {
                color: $primarycolor;
            }
        }

        .copyrightnav {
            .menu-item {
                display: inline-block;

                &::after {
                    content: "\2003|\2003";
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    &__pink {
        position: relative;
        top: 0;
        transform: translateY(-50%);
        z-index: 99;
        margin-bottom: -140px;

        &__holder {
            background: $primarycolor;
            color: #fff;
            border-radius: 25px;
            padding: 90px;

            h2 {
                color: #fff;
                margin-bottom: 10px;
                font-size: 3rem;
            }

            p {
                font-size: 1.5rem;
            }

            .btn {
                margin-top: 20px;
                font-size: 1.313rem;
                padding: 20px 30px;
                height: 60px;
            }

            .phone {
                background: #fff;
                color: $primarycolor;

                &:focus-within,
                &:hover {
                    color: #fff;
                }
            }

            .whatsapp {
                background: #25d366;
            }

            .mail {
                svg {
                    color: #fff;
                }
            }
        }
    }

    &__social {
        margin-bottom: 60px;

        li {
            display: inline-block;

            &::before {
                display: none;
            }

            &:last-child {
                a {
                    margin-right: 0;
                }
            }

            a {
                color: #fff;
                height: 35px;
                text-align: left;
                display: flex;
                align-items: center;
                margin-right: 20px;
            }
        }

        .social__icon__item {
            path {
                transition: all 300ms ease-in-out;
            }

            &:focus-within,
            &:hover {
                path {
                    fill: $primarycolor;
                }
            }

            &--facebook {
                svg {
                    width: auto;
                    height: 23px;
                }
            }

            &--linkedin {
                svg {
                    width: auto;
                    height: 20px;
                }
            }

            &--instagram {
                svg {
                    width: auto;
                    height: 20px;
                }
            }
        }
    }

    .menu-item {
        &::before {
            display: none;
        }
    }
}

@media screen and (max-width: 1678px) {
    .footer {
        margin-top: 185px;

        &__pink {
            margin-bottom: -185px;
        }
    }
}

@media screen and (max-width: 1349px) {
    .footer {
        margin-top: 200px;

        &__pink {
            margin-bottom: -200px;
        }
    }
}

@media screen and (max-width: 1049px) {
    .footer {
        margin-top: 225px;

        &__pink {
            margin-bottom: -225px;
        }
    }
}

@media screen and (max-width: 998px) {
    .footer {
        margin-top: 210px;

        &__pink {
            margin-bottom: -210px;
        }
    }
}

@media screen and (max-width: 991px) {
    .footer {
        margin-top: 135px;

        &__pink {
            margin-bottom: -135px;

            .btn {
                max-width: 360px;
            }
        }

        &__menu--one,
        &__menu--two {
            margin-bottom: 0;
        }

        &__menu--one,
        &__menu--two,
        &__menu--three,
        &__menu--four {
            padding-right: 0;
            max-width: 70%;
            min-width: 275px;
        }
    }

    .footernav {
        display: none;
    }

    .footer__holder {
        margin-top: 55px;
    }

    .footer__pink__holder {
        padding: 45px 30px;

        h2 {
            font-size: 1.75rem;
        }

        p {
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 1.125rem;
        }

        .btn {
            margin-top: 15px;
            justify-content: center;
            width: auto;
            font-size: 1rem;
            padding: 20px 15px;
        }
    }

    .footer__social {
        margin-bottom: 50px;
    }

    .footer__copyright {
        margin-top: 75px;
        margin-bottom: 35px;
        font-size: 0.688rem;
        line-height: 33px;

        a {
            font-size: 0.688rem;
        }
    }
}

@media screen and (max-width: 912px) {
    .footer {
        margin-top: 150px;

        &__pink {
            margin-bottom: -150px;
        }
    }
}

@media screen and (max-width: 550px) {
    .footer {
        margin-top: 175px;
    }

    .footer__pink__holder {
        .btn {
            width: auto;
            font-size: 1rem;
            padding: 20px 15px;
        }
    }
}

@media screen and (max-width: 527px) {
    .footer {
        margin-top: 180px;

        &__pink {
            margin-bottom: -180px;
        }
    }
}

@media screen and (max-width: 508px) {
    .footer {
        margin-top: 215px;

        &__pink {
            margin-bottom: -215px;
        }
    }

    .footer__pink__holder {
        .btn {
            width: 100%;
        }
    }
}
