.front__banner {
    width: 100%;
    min-height: 575px;
    height: 100vh;
    position: relative;
    overflow: hidden;

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
    }

    .banner__logo {
        max-width: 456px;
        width: 100%;
    }

    .slick-list {
        overflow: initial;
    }

    .slick-slide {
        transform: translateY(-100%);
        transition: 0.3s ease-in-out opacity, 0.5s ease-in-out transform !important;
        padding-left: 20px;

        &.slick-active {
            transform: translateY(0);
        }
    }
}

.front__banner__black {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-color: #000;
    z-index: 1;
}

.front__banner__left {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    pointer-events: none;
    z-index: 5;

    .reveal__wrap {
        display: block;
        max-width: 456px;
        width: 100%;
    }
}

.front__banner__right {
    position: absolute;
    left: 50%;
    top: 0;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    pointer-events: none;
    z-index: 4;

    .front__banner__content {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding-right: 40px;
    }
}

.front__banner__play {
    animation: spin 22s linear infinite;

    svg {
        width: 220px;
        height: 220px;
    }
}

.front__banner__slider {
    .slick-slide {
        cursor: default;
    }
}

.front__video__toggle__button {
    position: absolute;
    z-index: 50;
    right: 20px;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: transparent;
    border: solid 3px #fff;
    color: #fff;
    outline: none;
    border-radius: 50%;
    opacity: 0.5;
    transition: opacity 300ms ease-in-out;

    &:focus-within,
    &:hover {
        outline: none;
        opacity: 1;
    }

    svg {
        width: 20px;
        height: 20px;
        color: #fff;

        &:nth-child(1) {
            display: block;
        }

        &:nth-child(2) {
            display: none;
            width: 16px;
            height: 16px;
            margin-left: 2px;
        }
    }

    &.is-paused {
        svg {
            &:nth-child(1) {
                display: none;
            }

            &:nth-child(2) {
                display: block;
            }
        }
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.front__banner__title {
    font-size: 2.188rem;
    margin-bottom: 15px;
    color: $primarycolor;
    font-family: $font-secondary;
}

.front__banner__title__row {
    transform: translateY(-20px);
    opacity: 0;
    display: inline-block;
    pointer-events: auto;
}

.front__banner__subtitle {
    font-family: $fredoka-semi-bold;
    font-size: 5.625rem;
    color: #fff;
    max-width: 850px;
    line-height: 0.95;
    pointer-events: auto;
}

.front__banner__subtitle__row {
    transform: translateY(30px);
    opacity: 0;
    display: inline-block;
    pointer-events: auto;
}

.front__content {
    position: relative;
    min-height: 300vh;
}

.video__long__src {
    &:focus-within,
    &:hover {
        cursor: url("../../images/video-cursor.png"), auto;
    }
}

.main__content--home {
    background: #fff;
    position: relative;
}

.front__header {
    position: relative;
    z-index: 99;
    height: 100vh;
    min-height: 575px;
}

.front__parent {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    clip: rect(0, auto, auto, 0);
}

.front__scroll__outer {
    width: 254px;
    height: 254px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: calc(100% - 160px);
    transform: rotate(90deg) translateY(50%);
    z-index: 9999;

    span {
        position: absolute;
        right: calc(100% + 18px);
        color: $primarycolor;
        font-size: 1.563rem;
        font-family: $font-secondary;
        text-transform: lowercase;
    }

    &--high {
        top: calc(100% - 25vh);
        height: 33vh;
        width: 33vh;

        .front__scroll__line {
            width: 33vh;
        }
    }
}

.front__scroll {
    display: flex;
    align-items: center;
    justify-content: center;
}

.front__scroll__line {
    width: 254px;
    height: 2px;
    background-color: $primarycolor;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.front__scroll__animation {
    position: absolute;
    width: 100px;
    height: 2px;
    background-color: #fff;
    z-index: 110;
    left: -100px;
    animation: line 5s infinite;
}

@keyframes line {
    20% {
        transform: translateX(600px);
    }

    100% {
        transform: translateX(600px);
    }
}

.front__video__long {
    opacity: 0;
    z-index: 999;
}

.project__page__items--mid {
    transform: translateY(-120px);
}

.project__page__items--right {
    transform: translateY(-240px);
}

/** Methods
 * --------------------------------------------------
 */
.front-methods {
    overflow: hidden;
    padding: 90px 0;
}

.front-methods__container {
    position: relative;
    max-width: 1130px;
    margin: auto;
    padding: 0 30px;
}

.front-methods__top {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &::before {
        position: absolute;
        left: 0;
        top: 100%;
        transform: translateX(50px) translateY(-80px);
        width: 77px;
        height: 122px;
        content: '';
        background-image: url('../../images/arrow-down.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
    }
}

.front-methods__heading {
    flex-basis: 100%;
    flex-shrink: 1;
    padding: 0 80px 0;
    font-size: 1.5rem;
    text-align: center;
}

.front-methods__label {
    flex: 0 0 252px;
    height: 252px;
}

.front-methods__label__content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 252px;
    height: 252px;
    padding: 40px 30px;
    background-image: url('../../images/sticker.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    font-family: $headings-font-family;
    font-size: 1.625rem;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.2;
    transform: rotate(-5deg);
}

.front-methods__title {
    font-size: 3.75rem;
    color: $primarycolor;
}

.front-methods__wrapper {
    position: relative;
}

.front-methods__collection {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    z-index: 1;
    position: relative;

    > *::before {
        grid-column: span 1;
        display: block;
        width: 1px;
        height: 0;
        padding-bottom: 75%;
        content: '';
    }
}

.front-methods__spinner {
    position: absolute;
    inset: 0;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: spin 22s linear infinite;
}

.front-methods__play-button {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: transparent;
    border: solid 3px #000;
    color: #000;
    outline: none;
    border-radius: 50%;
    opacity: 0.1;
    transition: opacity 300ms ease-in-out;

    &:focus-within,
    &:hover {
        outline: none;
        opacity: 1;
    }

    svg {
        width: 20px;
        height: 20px;
        color: #000;

        &:nth-child(1) {
            display: block;
        }

        &:nth-child(2) {
            display: none;
            width: 16px;
            height: 16px;
            margin-left: 2px;
        }
    }

    &.is-paused {
        svg {
            &:nth-child(1) {
                display: none;
            }

            &:nth-child(2) {
                display: block;
            }
        }
    }
}

.front-method {
    position: relative;

    &:nth-child(1) {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3;
    }

    &:nth-child(3) {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 4;
    }

    &:nth-child(4) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
    }
}

.front-method__container {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0 0 30px;

    > * {
        width: 100%;
    }
}

.front-method__title {
    position: relative;
    font-size: 2.25rem;

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-68px) translateY(-10px);
        width: 48px;
        height: 48px;
        background-color: $primarycolor;
        background-image: url('../../svg/arrow-right-regular-white.svg');
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: center;
        border-radius: 50%;
        content: '';
    }
}

.front-method__intro {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 1.063rem;
}

/** Team
 * --------------------------------------------------
 */
.front-team {
    padding: 90px 0 110px;
    z-index: 1;
    position: relative;
}

.front-team__title {
    display: flex;
    justify-content: center;
    overflow: hidden;
    transform: translateY(80px);
    margin-top: -80px;
    position: relative;
    padding: 0 10px;
    z-index: 0;

    img {
        width: 1558px;
        max-width: 100%;
    }
}

.front-team__slider {
    overflow: hidden;

    .slick-list {
        padding-left: calc((100vw - 1680px) / 2);
    }

    .slick-slide {
        width: 290px;
        margin: 0 30px;
    }

    .team-member {
        display: block !important;
        padding: 4px;
    }

    .team-member__content {
        margin-bottom: 0;
    }
}

.front-methods__slider-arrows,
.front-news__slider-arrows,
.front-team__slider-arrows {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 18px;
}

.front-methods__slider-arrows {
    display: none;
}

.front-methods__slider-arrow,
.front-news__slider-arrow,
.front-team__slider-arrow {
    transition: opacity 0.15s;
    cursor: pointer;
    background: none;
    border: none;

    &--prev {
        transform: scaleX(-1);
    }

    &.slick-disabled {
        cursor: default;
        opacity: 0.45;
    }

    svg {
        width: 45px;
        height: 45px;
    }
}

@media screen and (max-width: 1799px) {
    .front-team__slider .slick-list {
        padding: 0 60px;
    }
}

@media screen and (max-width: 1699px) {
    .front__banner__title {
        font-size: 1.75rem;
    }

    .front__banner {
        .banner__logo {
            max-width: 300px;
        }
    }

    .front__banner__left {
        .reveal__wrap {
            max-width: 300px;
        }
    }

    .front__banner__subtitle {
        font-size: 4.5rem;
        line-height: 1.1;
    }

    .front__scroll__outer span {
        font-size: 1.125rem;
    }

    .front__scroll__outer {
        width: 100px;
        height: 100px;
        top: calc(100% - 70px);
    }

    .front__scroll__outer--high {
        top: calc(100% - 18vh);
        height: 25vh;
        width: 25vh;

        .front__scroll__line {
            width: 25vh;
        }
    }

    .front__scroll__line {
        width: 100px;
    }
}

@media screen and (max-width: 1559px) {
    .front-team__title {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 1199px) {
    .front-team__title {
        transform: translateY(40px);
        margin-top: -40px;
    }

    .project__page__items--mid {
        transform: translateY(-85px);
    }

    .project__page__items--right {
        transform: translateY(-170px);
    }

    .front__intro__link svg {
        width: 12px;
    }

    .front__intro__link {
        font-size: 1.125rem;
    }

    .front__projects__inner {
        margin-top: 80px;
        margin-bottom: 213px;
        gap: 20px;
    }

    .front__projects__inner .project__content--odd {
        margin-bottom: 20px;
    }

    .front__banner__title {
        font-size: 1.375rem;
        padding-right: 30px;
    }

    .front__banner__subtitle {
        font-size: 3.75rem;
        line-height: 1.2;
        padding-right: 30px;
    }

    .front__banner__play svg {
        width: 150px;
        height: 150px;
    }

    .front__banner__play__btn svg {
        width: 24px;
    }

    .front__banner__play__btn {
        width: 67px;
        height: 67px;
    }

    .front__banner__play__btn::before {
        width: 80px;
        height: 80px;
        left: -7px;
        top: -7px;
    }

    .front-team__slider {
        .slick-list {
            margin-right: -15px;
            margin-left: -15px;
        }

        .slick-slide {
            margin: 0 15px;
        }
    }

    .front-team__slider-arrows {
        margin-top: 10px;
    }

    .front-team__slider-arrow {
        transition: opacity 0.15s;
        cursor: pointer;

        &--prev {
            transform: scaleX(-1);
        }

        &.slick-disabled {
            cursor: default;
            opacity: 0.5;
        }

        svg {
            width: 45px;
            height: 45px;
        }
    }

    .front__scroll__outer--high {
        top: calc(100% - 18vh);
        height: 22vh;
        width: 22vh;

        .front__scroll__line {
            width: 22vh;
        }
    }

    .front-methods__collection {
        > *::before {
            padding-bottom: 85%;
        }
    }

    .front-methods__wrapper {
        padding-left: 38px;
    }
}

@media screen and (max-width: 991px) {
    .front__banner__subtitle {
        font-size: 2.5rem;
    }

    .front__banner__right {
        .front__banner__content {
            padding-right: 0;
        }
    }

    .front__banner {
        .banner__logo {
            max-width: 200px;
            width: 100%;
        }
    }

    .front__banner__left {
        .reveal__wrap {
            max-width: 200px;
        }
    }

    .front__scroll__outer--high {
        top: calc(100% - 11vh);
        height: 15vh;
        width: 15vh;

        .front__scroll__line {
            width: 15vh;
        }
    }

    .front-methods__play-button {
        display: none;
    }

    //.front-methods__top::before {
    //    transform: translateX(100px) translateY(-20px);
    //}

    .front-methods {
        padding: 40px 0;
    }

    .front-methods__top {
        flex-direction: column;
        gap: 20px;
        margin-bottom: 70px;

        &::before {
            top: auto;
            bottom: 0;
            transform: translateX(30px) translateY(-50px);
        }
    }

    .front-methods__heading {
        padding: 0;
        font-size: 1.313rem;
        text-align: left;
    }

    .front-methods__title {
        font-size: 2.5rem;
    }

    .front-methods__label {
        flex: 0 0 180px;
        width: 180px;
        height: 180px;
    }

    .front-methods__label__content {
        width: 180px;
        height: 180px;
        font-size: 1.063rem;
    }

    .front-methods__collection {
        display: block;

        > *::before {
            display: none;
        }

        .slick-list {
            overflow: visible;
        }

        .slick-slide {
            margin: 0 8px;
        }
    }

    .front-methods__wrapper {
        padding: 0;
    }

    .front-methods__spinner {
        display: none;
    }

    .front-method {
        display: block;
        width: 100%;
    }

    .front-method__container {
        position: relative;
        inset: initial;
        padding: 0 100px 0 10px;
    }

    .front-method__title {
        font-size: 1.75rem;
    }

    .front-method__intro {
        display: -webkit-box;
        -webkit-line-clamp: initial;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 0.938rem;
    }

    .front-methods__slider-arrows {
        display: flex;
        margin-top: 30px;
        padding: 0;
    }
}

@media screen and (max-width: 767px) {
    .front-team__title {
        transform: translateY(30px);
        margin-top: -30px;
    }

    .front__banner {
        display: flex;
        width: 100vw;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        gap: 30px;
        min-height: 550px;
    }

    .front__banner__black {
        z-index: 8;
    }

    .front__banner__title {
        font-size: 1.25rem;
        padding-right: 0;
    }

    .front__banner__subtitle {
        padding-right: 0;
    }

    .front__banner__play__outer {
        position: relative;
    }

    .front__parent {
        width: 100vw;
    }

    .front__banner__left,
    .front__banner__right {
        position: relative;
        top: initial;
        left: initial;
        width: 100%;
        height: auto;
        padding: 0 30px;
        text-align: center;
        justify-content: center;
        z-index: 1001;
    }

    .front__banner__left {
        order: 1;
    }

    .front__banner video {
        width: 100vw;
    }

    .front-team {
        padding: 15px 0 75px;
    }

    .front-team__slider {
        .slick-list {
            padding: 0 30px;
            margin-right: -8px;
            margin-left: -8px;
        }

        .slick-slide {
            margin: 0 8px;
        }
    }
}

@media screen and (max-width: 575px) {
    .front-methods__top {
        &::before {
            transform: translateX(0) translateY(-50px);
        }
    }

    .front-methods__label {
        align-self: flex-end;
    }

    .front-team__title {
        transform: translateY(20px);
        margin-top: -20px;

        img {
            width: 755px;
        }
    }
}

@media screen and (max-width: 375px) {
    .front__banner__subtitle {
        font-size: 1.875rem;
        line-height: 1.2;
    }

    .front__banner__title {
        font-size: 1.125rem;
    }

    .front__scroll__outer span {
        font-size: 1rem;
    }

    .front__scroll__outer {
        width: 50px;
        height: 50px;
        top: calc(100% - 20px);
    }

    .front__scroll__line {
        width: 50px;
    }

    .front__scroll__outer--high {
        top: calc(100% - 10vh);
        height: 12.5vh;
        width: 12.5vh;

        .front__scroll__line {
            width: 12.5vh;
        }
    }
}
