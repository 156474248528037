/* stylelint-disable */
a,
button,
.searchandfilter .sf-field-reset .search-filter-reset,
.searchandfilter .sf-field-reset [type=submit],
.gform_wrapper [type=submit],
[type='radio'],
[type='checkbox'] {
    &:focus-visible {
        outline-color: #07a3ec !important;
        outline-width: 2px !important;
        outline-style: dashed !important;
        outline-offset: 2px;
    }
}

:focus-visible {
    outline-color: #07a3ec !important;
    outline-width: 2px !important;
    outline-style: dashed !important;
    outline-offset: 2px;
}

.gfield input:focus, .gfield select:focus, .gfield textarea:focus {
    outline-color: #07a3ec !important;
    outline-width: 2px !important;
    outline-style: dashed !important;
    outline-offset: 2px;
}

.team-member.js-team-member .block__reveal > a {
    display: inline-block;
    width: 100%;
    border-radius: 30px 30px 0 0;
}

.block__img:focus-visible {
    display: inline-block;
    border-radius: 50px 50px 0 0;
}

.front-methods__play-button:focus-visible {
    opacity: 1;
}

/* stylelint-enable */

body {
    font-family: $font-primary;
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    line-height: $body-line-height;
    color: $body-font-color;
}

[id*="layer-"] {
    scroll-margin-top: 200px;
}

.main {
    padding: 0;
    overflow-x: hidden;
}

.referer__popup {
    display: none;
}

.grecaptcha-badge {
    display: none !important;
}

.referer__popup__content {
    border-radius: $border-radius;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    width: 500px;

    .btn {
        margin: 0 auto;
        display: flex;
    }

    .fancybox-button {
        display: none;
    }
}

.fancybox-is-open .fancybox-bg {
    opacity: 0.75;
}

.sub__header {
    position: relative;
    z-index: 50;
}

.main__content {
    position: relative;
    background-color: #fff;

    &.main__content--404 {
        display: inline-block;
        width: 100%;
    }
}

p {
    margin-bottom: 35px;

    &:last-child {
        margin-bottom: 0;
    }
}

.body-overflow-hidden {
    overflow: hidden;
}

.body-padding-right {
    padding-right: 17px;
}

strong {
    font-family: 'Graphik-Bold', sans-serif;
}

.intro__layer {
    padding-top: 240px;
    padding-bottom: 130px;
}

.svg-inline--fa {
    width: 30px;
    height: auto;
}

.text--pink {
    color: $primarycolor;
}

a.gif-tooltip {
    position: relative;
    display: inline-block;
    color: $body-font-color !important;
    cursor: default;

    &::before {
        display: none !important;
    }

    .gif-tooltip__img {
        position: absolute;
        top: -10px;
        left: 50%;
        max-width: 180px;
        border-radius: $block-radius;
        box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 0, rgba(0, 0, 0, 0.05) 0 0 0 0;
        transform: translateY(-100%) translateX(-50%) scale(0) rotate(-45deg);
        transform-origin: bottom left;
        transition: all 0.3s;
        content: attr(href);
    }

    span {
        position: relative;

        &::after {
            content: '';
            width: calc(100% - 5px);
            height: 0;
            position: absolute;
            left: 3px;
            bottom: -4px;
            border-bottom: 3px dotted #333;
        }
    }

    &:hover {
        color: $body-font-color !important;

        .gif-tooltip__img {
            box-shadow: rgba(0, 0, 0, 0.1) 0 10px 15px -3px, rgba(0, 0, 0, 0.05) 0 4px 6px -2px;
            transform: translateY(-100%) translateX(-50%) scale(1) rotate(0);
            content: initial;
        }
    }
}

ol li {
    list-style: auto;
    display: list-item;
    padding-left: 0;
    margin-left: -16px;

    &::marker {
        color: $primarycolor;
    }

    &::before {
        display: none;
    }
}

ul {
    padding-left: 0;

    &.check {
        margin: 0 0 16px 0;
        padding: 0;
        list-style: none;

        li {
            position: relative;
            margin-bottom: 2px;
            padding-left: 30px;

            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                position: absolute;
                top: 8px;
                left: 0;
                width: 28px;
                height: 28px;
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' style='color: %23ff006c;' focusable='false' data-prefix='far' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'%3E%3C/path%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: 18px;
                border: 0;
            }
        }
    }

    &.bullets {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            position: relative;
            flex: 0 0 calc(50% - 20px);

            &::before {
                margin-top: 10px;
            }
        }
    }
}

a {
    transition: all 300ms ease-in-out;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $primarycolor;
    }
}

.acf-map {
    width: 100%;
    height: 500px;
    margin: 0;
}

.acf-map img {
    max-width: inherit !important;
}

.service__content {
    padding: 350px 0 150px;
}

li {
    position: relative;
    padding-left: 23px;
    list-style: none;
    display: block;

    &::before {
        position: absolute;
        top: 11px;
        left: 0;
        content: '';
        border-radius: 100%;
        min-width: 10px;
        width: 10px;
        height: 10px;
        border: 2px solid $primarycolor;
        display: inline-block;
        margin-right: 12px;
    }
}

.archive__list {
    padding: 0 0 40px;
}

.slider__content {
    position: relative;

    a {
        position: relative;
        float: left;
    }
}

.slider__wrapper {
    overflow: hidden;
}

.slider__wrapper__top {
    position: relative;
}

.slider__play {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;

    svg {
        width: 50px;
        height: 50px;
        color: #fff;
    }
}

.slider__top__prev,
.slider__top__next {
    position: absolute;
    z-index: 9;
    top: 50%;
    margin-top: -40px;

    svg {
        width: 80px;
        height: 80px;
        cursor: pointer;
        color: rgba(#fff, 0.7);
    }
}

.slider__top__prev {
    left: 10px;
}

.slider__top__next {
    right: 10px;
}

.slick-slide {
    cursor: pointer;

    &.slick-current {
        cursor: default;
    }

    img {
        border-radius: $border-radius;
    }
}

.slider__nav {
    margin: 10px -5px 0;
    padding: 0 1px;
}

.slider__nav__item {
    position: relative;
    padding: 0 4px;

    .slider__play {
        svg {
            width: 25px;
            height: 25px;
        }
    }
}

.block {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px);
    margin-bottom: 60px;
    padding: 0 47px;

    &:focus-within,
    &:hover {
        .block__img__inner {
            img {
                transform: scale(1.03);
            }
        }
    }
}

.block__img__inner {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    border-radius: $border-radius;

    img {
        width: 100%;
        max-width: 100%;
        transition: all 1s ease;
        border-radius: $border-radius;
    }
}

.block__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 16px 0 4px 0;
    color: $body-font-color;

    &:hover,
    &:focus,
    &:active {
        color: $body-font-color;
    }
}

.block__title {
    display: block;
    font-size: 1.938rem;
    line-height: 1.3;
    font-family: $headings-font-family;
    color: $primarycolor;
    margin-top: 6px;
    transition: color 0.3s;

    &:hover,
    &:focus {
        color: $primarycolor;
        transition: color 0.3s;
    }
}

.block__subtitle {
    margin-top: 5px;
    margin-bottom: 20px;
    font-weight: 600;
}

.block__author {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 21px 32px;
    display: flex;
    align-items: center;
}

.block__author__img {
    img {
        width: 52px;
        min-width: 52px;
        border-radius: 80px;
        border: 4px solid #fff;
    }
}

.block__author__txt {
    color: #fff;
    font-size: 1.125rem;
    padding-left: 20px;
}

.block__phone,
.block__email {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 34px;
    word-break: break-all;

    svg {
        position: absolute;
        top: 4px;
        left: 0;
        width: 16px;
        height: 16px;
        color: $primarycolor;
        fill: $primarycolor;
    }
}

.block__phone {
    margin-bottom: 4px;
}

.block__no__img {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    background-color: #f9f9f9;
}

.block__date {
    display: block;
    font-size: 1rem;
}

.block__tags {
    line-height: 1.2;

    a {
        font-size: 0.938rem;
        text-decoration: none;
        color: #000;
        transition: all 150ms ease-in-out;

        &:focus-within,
        &:hover {
            color: $primarycolor;
            text-decoration: underline;
            transition: all 150ms ease-in-out;
        }
    }
}

.block__summary {
    display: block;
    height: 100%;
    margin: 15px 0 25px;
    font-size: 1rem;
    line-height: 1.5;
    color: $body-font-color;
}

.block__list {
    padding: 19px 0 0;
}

.block__item {
    position: relative;
    display: block;
    margin: 0 0 8px 20px;
    color: $body-font-color;

    &:last-child {
        margin-bottom: 0;
    }

    &::before {
        position: absolute;
        top: 5px;
        left: -18px;
        width: 6px;
        height: 18px;
        content: "";
        background: url('../../images/angle-right-regular.svg') no-repeat;
        background-size: 6px 16px;
    }
}

.single__content {
    padding: 290px 0 0 0;

    h3 {
        margin-top: 45px;
        margin-bottom: 13px;
    }
}

.accordion {
    margin: auto;
}

// Custom checkbox styling
.checkbox {
    display: flex;
    align-items: center;
    text-align: left;
}

.checkbox__input {
    display: none;
}

.checkbox__label {
    position: relative;
    display: flex;
    margin: 0;
    padding-right: 20px;
    cursor: pointer;
    line-height: 1.5;

    &::before {
        position: relative;
        display: inline-block;
        width: 15px;
        min-width: 15px;
        height: 15px;
        margin: 6px 10px 0;
        content: "";
        cursor: pointer;
        border: 1px solid #d5d5d5;
        border-radius: 3px;
        background-color: transparent;
    }
}

.checkbox .checkbox__input:checked + .checkbox__label::after {
    position: absolute;
    top: 8px;
    left: 15px;
    display: block;
    width: 5px;
    height: 9px;
    content: "";
    transform: rotate(45deg);
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
}

.container-fluid {
    padding-right: 120px;
    padding-left: 120px;

    .container-fluid {
        padding-right: 12px;
        padding-left: 12px;
    }
}

/* stylelint-disable */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .block {
        display: block;
        flex: none;
    }

    .block__content {
        display: block;
    }
}

/* stylelint-enable */

@media (max-width: 1199px) {
    h1 {
        font-size: 2.5rem;
    }

    h2 {
        font-size: 2.25rem;
    }

    h3 {
        font-size: 1.25rem;
    }

    h4 {
        font-size: 1.125rem;
    }

    h5 {
        font-size: 1rem;
    }

    body {
        font-size: 1rem;
    }

    p {
        margin-bottom: 25px;
    }

    li {
        font-size: 1rem;
        padding-left: 18px;

        &::before {
            margin-top: -5px;
        }
    }

    a.gif-tooltip {
        .gif-tooltip__img {
            top: -3px;
        }
    }

    .container-fluid {
        padding-right: 60px;
        padding-left: 60px;
    }

    .intro__layer {
        h1 {
            font-size: 4rem;
        }
    }

    .slider__top__prev,
    .slider__top__next {
        margin-top: -30px;
    }

    .slider__top__prev svg,
    .slider__top__next svg {
        width: 60px;
        height: 60px;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.7);
    }

    .slider__top__prev {
        left: 10px;
    }

    .slider__top__next {
        right: 10px;
    }

    .single__content {
        padding: 140px 0 0 0;

        h3 {
            margin-top: 32px;
            margin-bottom: 7px;
        }
    }

    .block__date {
        font-size: 0.875rem;
    }

    .block__title {
        font-size: 1.625rem;
    }

    .block__tags {
        line-height: inherit;

        a {
            font-size: 0.875rem;
        }
    }
}

@media (max-width: 991px) {
    a.gif-tooltip {
        .gif-tooltip__img {
            max-width: 120px;
        }
    }

    .block__author__txt {
        font-size: 1rem;
        line-height: 1.3;
    }

    .block__author {
        padding: 21px 22px;
    }
}

@media (max-width: 767px) {
    .referer__popup__content {
        width: calc(100% - 60px);
    }

    .intro__layer {
        padding-top: 150px;
        padding-bottom: 80px;

        h1 {
            font-size: 2.5rem;
        }
    }

    ul {
        &.bullets {
            flex-direction: column;

            li {
                flex: 0 0 100%;

                &::before {
                    margin-top: 6px;
                }
            }
        }
    }

    .block__summary {
        font-size: 0.938rem;
    }

    .container-fluid {
        padding-right: 30px;
        padding-left: 30px;
    }
}

@media (max-width: 475px) {
    .text--mobile-smaller {
        font-size: 1.625rem !important;
        line-height: 32px !important;
    }
}

@media (max-width: 374px) {

}
