/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";

$slick-loader-path: "~slick-carousel/slick/";
$slick-font-path: "~slick-carousel/slick/fonts/";

$primarycolor: #ff006c;
$contrastColor: #00ff93;
$secondarycolor: #000;
$greycolor: #ebebeb;

$graphik-regular: 'Graphik-Regular', sans-serif;
$graphik-bold: 'Graphik-Bold', sans-serif;
$fredoka-regular: 'Fredoka-Regular', sans-serif;
$fredoka-semi-bold: 'Fredoka-SemiBOld', sans-serif;
$fredoka-bold: 'Fredoka-Bold', sans-serif;

$font-primary: $graphik-regular;
$font-secondary: $fredoka-regular;
$body-font-size: 1.313rem;
$body-font-weight: 400;
$body-line-height: 1.6;
$body-font-color: #000;

// Headings: h1,h2,h3,h4,h5,h6
$headings-margin-bottom: 20px;
$headings-font-family: $fredoka-bold;
$headings-font-weight: 600;
$headings-line-height: 1;
$headings-color: $body-font-color;

// Header:
$header-height: 130px;

$border-radius: 50px;
$block-radius: 30px;
$input-radius: 8px;

$theme-colors: (
    primary: $primarycolor
);

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

//Bootstrap
$grid-gutter-width: 24px !default;

$enable-responsive-font-sizes: false;
$h1-font-size: 5rem;
$h2-font-size: 3.438rem;
$h3-font-size: 1.875rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.125rem;
