.main__content {
    .frm_forms {
        &.frm_style_formidable-style {
            &.with_frm_style {
                label {
                    font-family: $font-primary;
                }

                .frm_description {
                    p {
                        font-size: 1rem;
                        margin-top: 25px;
                        color: $secondarycolor;
                        font-family: $graphik-bold;
                    }
                }

                .frm_opt_container {
                    label {
                        font-size: 1rem;
                    }
                }

                .frm_primary_label {
                    font-size: 1rem;
                    margin-bottom: 15px;
                    color: $secondarycolor;
                    font-family: $graphik-bold;
                }

                .form-field {
                    margin-bottom: 0;
                }
            }
        }

        &.with_frm_style {
            .frm_form_fields {
                > fieldset {
                    padding-bottom: 0;
                }
            }
        }

        .frm_fields_container {
            grid-gap: 25px 2%;
        }

        .select2-container--default.select2-container--above {
            .select2-selection {
                &[aria-expanded="true"] {
                    border-top-right-radius: 0;
                    border-top-left-radius: 0;
                    border-top: 1px solid $white;
                }
            }
        }

        .select2-container--default.select2-container--below {
            .select2-selection {
                &[aria-expanded="true"] {
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                    border-bottom: 1px solid $white;
                }
            }
        }

        textarea {
            max-height: 170px;
        }

        textarea,
        input {
            &:focus,
            &:active {
                outline: 0;
                box-shadow: none !important;
                border-color: $body-font-color !important;
                border: 1px solid $body-font-color;
            }
        }

        .select2-container--default .select2-selection--single,
        select,
        textarea,
        input {
            border: 1px solid rgba(83, 83, 83, 0.4);
            font-size: 1.188rem;
            //padding: 15px 20px;
            padding: 28px 35px;
            height: inherit;
            box-shadow: none;
            color: $body-font-color;
            width: 100%;
            font-family: $graphik-regular;
            font-weight: 400;
            border-radius: $input-radius;

            .select2-selection__placeholder,
            &::placeholder {
                color: $body-font-color;
            }
        }

        &.frm_style_formidable-style.with_frm_style .frm_loading_form .frm_button_submit,
        &.frm_style_formidable-style.with_frm_style .frm_loading_form .frm_button_submit:hover {
            background: $white;
            color: $primarycolor !important;

            &::before {
                border: 0;
            }
        }

        .frm_submit {
            button {
                @extend .btn;
                @extend .btn--primary;

                padding-left: 67px;
                padding-right: 37px;
                padding-bottom: 10px;
                text-transform: lowercase;
                margin-bottom: 0;

                &:hover,
                &:active,
                &:focus {
                    background: $primarycolor;
                    color: $white;

                    &::before {
                        background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' style='color: %23ffffff;' focusable='false' data-prefix='far' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M501.6 4.186c-7.594-5.156-17.41-5.594-25.44-1.063L12.12 267.1C4.184 271.7-.5037 280.3 .0431 289.4c.5469 9.125 6.234 17.16 14.66 20.69l153.3 64.38v113.5c0 8.781 4.797 16.84 12.5 21.06C184.1 511 188 512 191.1 512c4.516 0 9.038-1.281 12.99-3.812l111.2-71.46l98.56 41.4c2.984 1.25 6.141 1.875 9.297 1.875c4.078 0 8.141-1.031 11.78-3.094c6.453-3.625 10.88-10.06 11.95-17.38l64-432C513.1 18.44 509.1 9.373 501.6 4.186zM369.3 119.2l-187.1 208.9L78.23 284.7L369.3 119.2zM215.1 444v-49.36l46.45 19.51L215.1 444zM404.8 421.9l-176.6-74.19l224.6-249.5L404.8 421.9z'%3E%3C/path%3E%3C/svg%3E%0A");
                    }
                }

                &::before {
                    position: absolute;
                    top: 0;
                    left: 30px;
                    width: 20px;
                    height: 100%;
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' style='color: %23ff006c;' focusable='false' data-prefix='far' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M501.6 4.186c-7.594-5.156-17.41-5.594-25.44-1.063L12.12 267.1C4.184 271.7-.5037 280.3 .0431 289.4c.5469 9.125 6.234 17.16 14.66 20.69l153.3 64.38v113.5c0 8.781 4.797 16.84 12.5 21.06C184.1 511 188 512 191.1 512c4.516 0 9.038-1.281 12.99-3.812l111.2-71.46l98.56 41.4c2.984 1.25 6.141 1.875 9.297 1.875c4.078 0 8.141-1.031 11.78-3.094c6.453-3.625 10.88-10.06 11.95-17.38l64-432C513.1 18.44 509.1 9.373 501.6 4.186zM369.3 119.2l-187.1 208.9L78.23 284.7L369.3 119.2zM215.1 444v-49.36l46.45 19.51L215.1 444zM404.8 421.9l-176.6-74.19l224.6-249.5L404.8 421.9z'%3E%3C/path%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: 20px;
                    background-position: center 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .main__content {
        .frm_forms {
            .select2-container--default .select2-selection--single,
            select,
            textarea,
            input {
                font-size: 1.063rem;
                padding: 15px;
            }

            .frm_submit {
                button {
                    font-size: 1.375rem;

                    &::before {
                        background-size: 16px;
                        background-position: center 16px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .main__content {
        .frm_forms {
            .frm_fields_container {
                grid-gap: 15px 2%;
            }

            .select2-container--default .select2-selection--single,
            select,
            textarea,
            input {
                padding: 18px 25px;
                line-height: 23px;
            }

            .select2-container--default .select2-selection--single .select2-selection__rendered {
                line-height: 23px;
            }
        }
    }
}
