/* Overview */
.team__page__items {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 100px;
    row-gap: 25px;
    padding-bottom: 80px;
}

.team__overview__link {
    margin-top: 15px;
}

/* Ooerview items */
.team-member {
    position: relative;
    display: block;

    video {
        border-radius: $block-radius;
    }

    &:focus-within,
    &:hover {
        a.team-member__button {
            transform: translateY(0);
            opacity: 1;
            pointer-events: initial;
        }
    }
}

.team-member__media {
    position: relative;
    display: block;
    padding-bottom: 150%;
    border-radius: $block-radius;
    overflow: hidden;

    svg,
    img,
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
    }
}

span.team-member__content {
    position: relative;
    padding: 35px 0 0;
    display: inline-block;
    color: initial;
}

div.team-member__content {
    position: relative;
    padding: 0;
    display: inline-block;
    color: initial;
    margin-bottom: 35px;
}

.team-member__headings {
    position: relative;
}

.team-member__title {
    position: relative;
    display: inline-block;
    font-family: $headings-font-family;
    font-size: 1.938rem;
    line-height: 1;
    margin-bottom: 10px;
    color: $primarycolor;
    transition: all 300ms ease-in-out;
}

.team-member:hover .team-member__title,
.team-member:focus-within .team-member__title {
    color: $secondarycolor;
}

.team-member__subtitle {
    font-size: 1.188rem;
}

.team-member__buttons {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 10px;
    transition: all 0.3s;
    transition-delay: 0s;
}

a.team-member__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 24px;
    max-width: 24px;
    height: 24px;
    margin-left: 16px;
    color: $primarycolor;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s;
    pointer-events: none;

    &:first-child {
        margin-left: 0;
    }

    &:nth-child(2) {
        transition-delay: 0.15s;
    }

    &:nth-child(3) {
        transition-delay: 0.3s;
    }

    &:nth-child(4) {
        transition-delay: 0.45s;
    }

    &:hover,
    &:active,
    &:focus {
        color: $secondarycolor;
    }

    svg {
        position: relative;
        width: 24px;
        height: 24px;
        z-index: 1;
    }
}

/* Detail */
.single__main--team,
.single__sidebar--team {
    margin-bottom: 160px;
}

.team__function {
    font-size: 1.938rem;
    margin: -5px 0 30px 0;
}

.team__details {
    list-style: none;
    margin: 0;
}

.team__detail {
    &::before {
        display: none;
    }

    a {
        position: relative;
        display: flex;
        align-items: center;
        margin: 0 32px 8px 0;
        color: $body-font-color;

        &:focus,
        &:hover {
            color: $primarycolor;
        }

        svg {
            margin: 0 20px 0 0;
            width: 22px;
            min-width: 22px;
            height: 22px;
            color: $primarycolor;
            fill: $primarycolor;
        }
    }
}

.team__single-video {
    max-width: 444px;

    video {
        display: block;
        max-width: 100%;
        border-radius: $border-radius;
    }
}

.team__subtitle {
    text-align: center;
}

.team__news__headings {
    margin-bottom: 55px;
}

.team__news__collection {
    overflow: hidden;
    margin-bottom: 70px;
    margin-left: -36px;
    margin-right: -36px;

    .block {
        padding: 0 25px;
    }
}

@media screen and (max-width: 1500px) {
    span.team-member__content {
        padding-top: 30px;
        margin-bottom: 0;
    }

    div.team-member__content {
        padding-top: 0;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 1199px) {
    .team-member__title {
        font-size: 1.5rem;
    }

    .team__page__items {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 60px;
        row-gap: 10px;
        padding-bottom: 30px;
    }

    span.team-member__content {
        padding-top: 20px;
        padding-bottom: 0;
    }

    div.team-member__content {
        padding-top: 0;
        padding-bottom: 20px;
    }

    a.team-member__button {
        transform: none;
        opacity: 1;
    }

    .single__main--team {
        margin-bottom: 0;
    }

    .single__sidebar--team {
        margin-bottom: 60px;
    }

    .team__function {
        margin: -10px 0 20px 0;
        font-size: 1.75rem;
    }

    .team__news__collection {
        .block {
            padding: 0;
        }
    }
}

@media screen and (max-width: 991px) {
    .single__sidebar--team {
        margin-top: 0;
        margin-bottom: 30px;
    }

    .team-member__title {
        font-size: 1.5rem;
    }

    .team__news__headings {
        margin-top: 50px;
    }
}

@media screen and (max-width: 767px) {
    .team__page__items {
        column-gap: 30px;
        padding-bottom: 0;

        .team-member {
            &:nth-child(3n-1) {
                top: initial;
            }

            &:nth-child(3n+1) {
                top: initial;
            }

            &:nth-child(even) {
                top: initial;
            }
        }
    }

    span.team-member__content {
        padding-top: 20px;
        margin-bottom: 0;
    }

    div.team-member__content {
        padding-top: 0;
        margin-bottom: 20px;
    }

    .team-member__subtitle {
        font-size: 1rem;
    }

    .team__news__headings {
        margin-bottom: 20px;
        margin-top: 35px;
    }

    .team__news__collection {
        margin-left: -30px;
        margin-right: -30px;
        margin-bottom: 40px;

        .slick-list {
            margin-right: 0;
            margin-left: 0;
        }

        .block {
            margin-bottom: 0;
        }
    }
}

@media screen and (max-width: 575px) {
    .team__page__items {
        grid-template-columns: repeat(1, 1fr);
    }
}
