body#tinymce {
    margin: 12px !important;

    blockquote {
        border-left: 9px solid #efefef;
        padding-left: 41px;
        font-size: 1.938rem;
        line-height: 1.3;
        margin: 70px 0;
        color: $primarycolor;
        font-family: $fredoka-bold;
    }
}
