.domain__hosting__form {
    #frm_field_33_container,
    #frm_field_32_container {
        display: none;
    }

    #field_j5zoh_label,
    #field_nqvw5_label {
        &:hover {
            cursor: pointer;
        }
    }

    .frm_primary_label {
        svg {
            width: 22px;
            margin-left: 8px;
            margin-top: -4px;
        }
    }
}

.frm_html_container {
    max-width: 900px;
}
