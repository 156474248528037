/* Pagination numbered */
.pagination__numbers {
    border-top: 1px solid #eee;
    width: 100%;
    padding-top: 50px;
    display: flex;
    justify-content: center;

    .page-numbers {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        background: $primarycolor;
        display: flex;
        float: left;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 0.938rem;
        margin: 0 3px;
        text-decoration: none;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover,
        &:focus {
            background-color: darken($primarycolor, 5);
            text-decoration: none;
        }

        &.current {
            background-color: darken($primarycolor, 5);
        }
    }
}

blockquote {
    border-left: 9px solid #efefef;
    padding-left: 41px;
    font-size: 1.938rem;
    line-height: 1.3;
    margin: 70px 0;
    color: $primarycolor;
    font-family: $fredoka-bold;
}

@media screen and (max-width: 767px) {
    blockquote {
        border-left: 8px solid #efefef;
        padding-left: 28px;
        font-size: 1.5rem;
        line-height: 1.2;
        margin: 40px 0;
    }
}
