.news__page__title--tag {
    text-align: center;
}

.news__page__items {
    padding-bottom: 60px;

    .block {
        position: relative;
        display: block;
        z-index: 1;
        height: inherit;
        margin-bottom: 55px;
        padding: 0 18px;
    }
}

.single__news__inner {
    padding: 0 140px;

    h2 {
        @extend h3;
    }

    figure {
        max-width: 100%;
    }

    img {
        border-radius: $block-radius;
        margin: 10px 0;
        max-width: 100%;
    }

    a {
        color: $primarycolor;
        position: relative;
        align-self: flex-start;
        display: inline-block;

        &::before {
            content: '';
            bottom: 0;
            left: 0;
            width: 0;
            height: 2px;
            background: $primarycolor;
            position: absolute;
            transition: all 300ms ease-in-out;
        }

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            color: $primarycolor;

            &::before {
                width: 100%;
            }
        }
    }
}

.single__news__inner--bottom {
    margin-top: 35px;
    padding-bottom: 120px;

    .col {
        h2,
        h3,
        h4,
        h5 {
            &:first-child {
                margin-top: 0;
            }
        }
    }
}

.single__news__img {
    max-height: 626px;
    width: 100%;
    object-fit: cover;
    border-radius: $block-radius;
    margin-top: 65px;
    margin-bottom: 35px;
}

.single__news__date {
    font-size: 1.188rem;
    margin-top: 40px;
}

.single__news__tags {
    font-size: 0.938rem;

    a {
        color: #969696;

        &:hover {
            color: $primarycolor;
            text-decoration: none;
        }
    }
}

.single__news__intro {
    margin-top: 25px;
    font-family: $graphik-bold;
}

.single__news__team {
    text-align: center;
    margin-top: 100px;
}

.single__news__team__name {
    font-family: $graphik-bold;
    font-style: italic;
}

.single__news__team__video {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 10px;

    &::before {
        display: none;
    }

    &:focus-within {
        .single__news__team__video__name {
            opacity: 1;
        }
    }

    video {
        width: 146px;
        height: 265px;
        border-radius: 220px;
        object-fit: cover;

        &:hover {
            + .single__news__team__video__name {
                opacity: 1;
            }
        }
    }
}

.single__news__team__video__name {
    position: absolute;
    bottom: 52px;
    left: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: $fredoka-bold;
    opacity: 0;
    transition: all 0.5s ease;
}

@media screen and (max-width: 1199px) {
    .single__news__date {
        font-size: 0.875rem;
    }

    .single__news__tags {
        font-size: 0.875rem;
    }

    .single__news__img {
        margin-top: 35px;
        margin-bottom: 0;
    }

    .single__news__team {
        margin-top: 60px;
    }

    .single__news__team__video {
        video {
            width: 102px;
            height: 185px;
        }
    }

    .single__news__inner--bottom {
        padding-bottom: 110px;
    }
}

@media screen and (max-width: 991px) {
    .single__news__inner {
        padding: 0 70px;

        img {
            margin: 0;
        }
    }

    .single__news__inner--bottom {
        padding-bottom: 70px;
    }

    .news__page__items .block {
        padding: 0 8px;
    }

    .page-layer--collection {
        &.page-layer--news {
            .page-layer__content__content {
                margin-top: 0;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .single__news__inner {
        padding: 0;
    }

    .single__news__img {
        width: calc(100% + 30px);
        max-width: calc(100% + 30px);
        margin-left: -15px;
    }

    .single__news__team {
        margin-top: 35px;
    }

    .single__news__inner--bottom {
        padding-bottom: 70px;
    }

    .news__page__items {
        padding-bottom: 20px;

        .block {
            margin-bottom: 40px;
            padding: 0;
        }
    }
}
